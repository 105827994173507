import FacebookIcon from "components/Icons/share/facebook.svg";
import InstagramIcon from "components/Icons/share/instagram.svg";
import TwitterIcon from "components/Icons/share/twitter.svg";
import YoutubeIcon from "components/Icons/share/youtube.svg";

import { useFooterClickEventTracker } from "./useFooterClickEventTracker";

const SocialIconLinks = () => {
  const { createPublishFooterClickEventFunction } =
    useFooterClickEventTracker();

  return (
    <>
      <a
        className="a block px-md"
        href="http://www.facebook.com/leaflydotcom"
        title="Leafly on Facebook"
        onClick={createPublishFooterClickEventFunction("facebook")}
      >
        <FacebookIcon width={24} height={24} />
      </a>
      <a
        className="block px-md"
        href="http://twitter.com/leafly"
        title="Leafly on Twitter"
        onClick={createPublishFooterClickEventFunction("twitter")}
      >
        <TwitterIcon width={24} height={24} />
      </a>
      <a
        className="block px-md"
        href="https://www.instagram.com/heyleafly"
        title="Leafly on Instagram"
        onClick={createPublishFooterClickEventFunction("instagram")}
      >
        <InstagramIcon width={24} height={24} />
      </a>
      <a
        className="block px-md"
        href="https://www.youtube.com/channel/UCqVnkns6Gf3W7J9zWZE4EqA"
        title="Leafly on YouTube"
        onClick={createPublishFooterClickEventFunction("youtube channel")}
      >
        <YoutubeIcon width={30} height={24} />
      </a>
    </>
  );
};

export default SocialIconLinks;
