"use client";

import React from "react";
import dynamic from "next/dynamic";
import Link from "next/link";

import {
  FOOTER_MEDICAL_DISCLAIMER_CA,
  FOOTER_MEDICAL_DISCLAIMER_US,
} from "constants/disclaimers";

import FeedbackSurvey from "components/FeedbackSurvey";
import LegalLogo from "components/Icons/legal-logo.svg";
import MedicalDisclaimers from "components/MedicalDisclaimers";

import { AboutLeafly } from "./AboutLeafly";
import { AppStoreButtons } from "./AppStoreButtons";
import { BusinessSolutions } from "./BusinessSolutions";
import { Copyright } from "./Copyright";
import { DispensaryQuickLinks } from "./DispensaryQuickLinks";
import { NewsletterSignup } from "./NewsletterSignup";
import { PrivacyAndTerms } from "./PrivacyAndTerms";
import SocialIconLinks from "./SocialIconLinks";
import { useFooterClickEventTracker } from "./useFooterClickEventTracker";

const ChatContainer = dynamic(() => import("../chat/ChatContainer"), {
  ssr: false,
});

const HrSeperatorOne = () => (
  <hr className="border-light-grey lg:hidden p-[0_10px] m-[0_-10px]" />
);
const HrSeperatorTwo = () => <hr className="border-light-grey" />;

export const Footer = () => {
  const { createPublishFooterClickEventFunction } =
    useFooterClickEventTracker();

  return (
    <footer className="footer bg-leafly-white border-t border-light-grey flex flex-col text-default">
      <FeedbackSurvey surveyId="jAU3zvrF" />
      <div className="container flex flex-row lg:my-lg md:my-sm justify-center">
        <Link
          className="a my-lg relative text-green"
          href="/"
          aria-label="Leafly"
          onClick={createPublishFooterClickEventFunction("leafly_logo_home")}
        >
          <LegalLogo width="84" height="33" />
        </Link>
      </div>
      <HrSeperatorTwo />
      <div className="flex lg:flex-row lg:py-xl py-md justify-center items-center">
        <SocialIconLinks />
      </div>
      <HrSeperatorTwo />
      <div className="container lg:pb-md">
        <div className="row">
          <div className="md:col-1/2 lg:col-2/3">
            <NewsletterSignup />
          </div>
          <div className="pt-xl md:col-1/2 lg:col-1/3">
            <AppStoreButtons />
          </div>
        </div>
      </div>
      <HrSeperatorTwo />
      <div className="container">
        <div className="row lg:pb-md">
          <section className="col lg:col-1/4">
            <BusinessSolutions />
          </section>
          <HrSeperatorOne />
          <section className="col lg:col-1/4">
            <AboutLeafly />
          </section>
          <HrSeperatorOne />
          <section className="nav__stores col lg:col-1/4">
            <DispensaryQuickLinks />
          </section>
          <HrSeperatorOne />
          <section className="nav__stores col lg:col-1/4">
            <PrivacyAndTerms />
          </section>
        </div>
      </div>
      <MedicalDisclaimers
        className="container mb-xl"
        usDisclaimer={FOOTER_MEDICAL_DISCLAIMER_US}
        caDisclaimer={FOOTER_MEDICAL_DISCLAIMER_CA}
      />
      <HrSeperatorTwo />
      <section className="border-b border-light-grey container">
        <Copyright />
      </section>
      <div className="fixed bottom-0 right-0 chat">
        <ChatContainer />
      </div>
    </footer>
  );
};

export default Footer;
