import { Cart, CartItem } from "custom-types/Cart";

type Props = {
  discountAmount?: number | null;
  discountedPriceCents: number;
  discountType?: string | null;
  priceCents: number;
  savingsCents: number;
};

const defaultCartTotals = {
  deliveryFee: 0,
  exciseTax: 0,
  hasTax: false,
  otherTax: 0,
  salesTax: 0,
  savings: null,
  subtotal: 0,
  tax: 0,
  total: 0,
  totalItems: 0,
};

function centsToString(cents: number) {
  return (cents / 100).toFixed(2);
}

export function calculateItemTotals({
  discountAmount,
  discountedPriceCents,
  discountType,
  priceCents,
  savingsCents,
}: Props) {
  const itemTotals = {
    dealExists: savingsCents !== 0,
    discountDeal: !!(discountType && discountAmount),
    discountedPrice: centsToString(discountedPriceCents),
    price: centsToString(priceCents),
    savings: centsToString(savingsCents),
  };

  return itemTotals;
}

/**
 * tallyItems
 * @param {Array} cartItems the array of cartItems within a `cart`
 * @returns {Number} Returns total number of items in a cart
 */
export const tallyItems = (cartItems: CartItem[]) =>
  cartItems.reduce((prev, curr) => prev + curr.quantity || 0, 0);

/**
 * consumeCartTotals.
 *
 * @param {Object} cart the cart object
 * @param {Number} deliveryFee delivery fee in dollars
 * @returns {Object} cartTotals with all values in dollars
 */

export const consumeCartTotals = (
  cart: Cart | undefined | null,
  deliveryFee?: number | null,
) => {
  if (!cart) {
    return defaultCartTotals;
  }
  const deliveryFeeInCents = deliveryFee ? deliveryFee * 100 : 0;

  const getTaxByCategory = (category: string) => {
    const { taxTotals } = cart;

    if (!taxTotals) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    const taxCategory = taxTotals.find((tax: any) => tax.category === category);

    return taxCategory ? centsToString(taxCategory.amountCents) : null;
  };

  return {
    deliveryFee: centsToString(deliveryFeeInCents),
    exciseTax: getTaxByCategory("Excise"),
    hasTax: cart.tax > 0,
    otherTax: getTaxByCategory("Other"),
    salesTax: getTaxByCategory("Sales"),
    savings: cart.totalDiscountsCents
      ? centsToString(cart.totalDiscountsCents)
      : null,
    subtotal: centsToString(cart.subtotal || 0),
    tax: centsToString(cart.tax || 0),
    total: centsToString(cart.total + cart.tax + deliveryFeeInCents || 0),
    totalItems: cart.cartItems ? tallyItems(cart.cartItems) : 0,
  };
};
