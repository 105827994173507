import { paymentOptions } from "constants/checkout";
import { ReservationsApiError } from "constants/customErrors";
import { Order } from "custom-types/Order";

export type OrderState = {
  claimReservationFailure: boolean | null;
  claimReservationSuccess: boolean | null;
  data: Order | null;
  errors: {
    getReservation: boolean;
    saveReservation: boolean;
  };
  loading: {
    getReservation: boolean;
    saveReservation: boolean;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  payfirmaScriptStatus: any | null;
  paymentErrorMsg: string;
  reservationId: number | null;
  selectedPaymentOption: string;
};

export const initialState: OrderState = {
  claimReservationFailure: null,
  claimReservationSuccess: null,
  data: null,
  errors: {
    getReservation: false,
    saveReservation: false,
  },
  loading: {
    getReservation: false,
    saveReservation: false,
  },
  payfirmaScriptStatus: null,
  paymentErrorMsg: "",
  reservationId: null,
  selectedPaymentOption: paymentOptions.inStore,
};

export const SET_PAYFIRMA_SCRIPT_STATUS = "SET_PAYFIRMA_SCRIPT_STATUS";
export const SET_PAYFIRMA_ERROR_MESSAGE = "SET_PAYFIRMA_ERROR_MESSAGE";
export const SET_SELECTED_PAYMENT_OPTION = "SET_SELECTED_PAYMENT_OPTION";

export const SAVE_ORDER_REQUEST = "SAVE_ORDER_REQUEST";
export const SAVE_ORDER_SUCCESS = "SAVE_ORDER_SUCCESS";
export const SAVE_ORDER_FAILURE = "SAVE_ORDER_FAILURE";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";

export const CLAIM_ORDER_SUCCESS = "CLAIM_ORDER_SUCCESS";
export const CLAIM_ORDER_FAILURE = "CLAIM_ORDER_FAILURE";

export type SetPayfirmaScriptStatusAction = {
  type: typeof SET_PAYFIRMA_SCRIPT_STATUS;
  payload: { status: string };
};
export type SetPayfirmaErrorMessageAction = {
  type: typeof SET_PAYFIRMA_ERROR_MESSAGE;
  payload: { msg: string };
};
export type SetSelectedPaymentOptionAction = {
  type: typeof SET_SELECTED_PAYMENT_OPTION;
  payload: string;
};

export type SaveOrderRequestAction = {
  type: typeof SAVE_ORDER_REQUEST;
};
export type SaveOrderSuccessAction = {
  type: typeof SAVE_ORDER_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  payload: any;
};
export type SaveOrderFailureAction = {
  type: typeof SAVE_ORDER_FAILURE;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  payload: any;
};

export type GetOrderRequestAction = {
  type: typeof GET_ORDER_REQUEST;
};
export type GetOrderSuccessAction = {
  type: typeof GET_ORDER_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  payload: any;
};
export type GetOrderFailureAction = {
  type: typeof GET_ORDER_FAILURE;
};

export type ClaimOrderSuccessAction = {
  type: typeof CLAIM_ORDER_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  payload: any;
};
export type ClaimOrderFailureAction = {
  type: typeof CLAIM_ORDER_FAILURE;
};

export type OrderActions =
  | SetPayfirmaScriptStatusAction
  | SetPayfirmaErrorMessageAction
  | SetSelectedPaymentOptionAction
  | SaveOrderRequestAction
  | SaveOrderSuccessAction
  | SaveOrderFailureAction
  | GetOrderRequestAction
  | GetOrderSuccessAction
  | GetOrderFailureAction
  | ClaimOrderSuccessAction
  | ClaimOrderFailureAction;

export const orderReducer = (
  state = initialState,
  action: OrderActions,
): OrderState => {
  switch (action.type) {
    case SET_PAYFIRMA_SCRIPT_STATUS:
      return {
        ...state,
        payfirmaScriptStatus: action.payload.status,
      };
    case SET_PAYFIRMA_ERROR_MESSAGE:
      return {
        ...state,
        paymentErrorMsg: action.payload.msg,
      };
    case SET_SELECTED_PAYMENT_OPTION:
      return {
        ...state,
        paymentErrorMsg:
          action.payload === paymentOptions.inStore
            ? initialState.paymentErrorMsg
            : state.paymentErrorMsg,
        selectedPaymentOption: action.payload,
      };
    case SAVE_ORDER_REQUEST:
      return {
        ...state,
        errors: {
          // TODO: in web-res, the following line is '...state.error'. typo?
          ...state.errors,
          saveReservation: initialState.errors.saveReservation,
        },
        loading: {
          ...state.loading,
          saveReservation: true,
        },
        paymentErrorMsg: initialState.paymentErrorMsg,
      };
    case SAVE_ORDER_SUCCESS: {
      const reservationId = action.payload.id;
      return {
        ...state,
        data: action.payload,
        errors: {
          ...state.errors,
          saveReservation: initialState.errors.saveReservation,
        },
        loading: {
          ...state.loading,
          saveReservation: false,
        },
        paymentErrorMsg: initialState.paymentErrorMsg,
        reservationId,
      };
    }
    case SAVE_ORDER_FAILURE: {
      const newState = {
        ...state,
        errors: {
          ...state.errors,
          saveReservation: true,
        },
        loading: {
          ...state.loading,
          saveReservation: false,
        },
      };
      const paymentError = action.payload?.find((error: ReservationsApiError) =>
        error.title?.toLowerCase().includes("payment"),
      );
      if (paymentError) {
        return {
          ...newState,
          paymentErrorMsg: paymentError.detail?.split("reason=")[0].trim(),
        };
      }
      return newState;
    }
    case GET_ORDER_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          getReservation: initialState.errors.getReservation,
        },
        loading: {
          ...state.loading,
          getReservation: true,
        },
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        errors: {
          ...state.errors,
          getReservation: initialState.errors.getReservation,
        },
        loading: {
          ...state.loading,
          getReservation: initialState.loading.getReservation,
        },
      };
    case GET_ORDER_FAILURE:
      return {
        ...state,
        data: initialState.data,
        errors: {
          ...state.errors,
          getReservation: true,
        },
        loading: {
          ...state.loading,
          getReservation: initialState.loading.getReservation,
        },
      };
    case CLAIM_ORDER_SUCCESS:
      return {
        ...state,
        claimReservationSuccess: true,
        data: action.payload,
      };
    case CLAIM_ORDER_FAILURE:
      return {
        ...state,
        claimReservationFailure: true,
      };
    default:
      return state;
  }
};

export default orderReducer;
