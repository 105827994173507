import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "redux/reducers/rootReducer";
import {
  getShortenedTimeZone,
  getStatusMessage,
} from "utils/dispensaryScheduleUtils";

import { getDomainCountryCode } from "./config";
import {
  buildGoogleMapsUrl,
  getLocalizedDistanceAnyDispensary,
} from "./dispensaryHelper";
import { getCoordinates } from "./location";

const getDispensary = (state: RootState) => state.dispensary;

export const getCurrentDispensary = createSelector(
  [getDispensary],
  (dispensary) => {
    return dispensary.currentDispensary;
  },
);

export const getCurrentDeliveryZone = createSelector(
  [getDispensary],
  (dispensary) => {
    return dispensary.deliveryZone;
  },
);

const getWithinDeliveryRange = createSelector(
  [getCurrentDispensary],
  (dispensary) => {
    const {
      deliveryZoneRangeType,
      deliveryZonePostalCodeWhitelist,
      deliveryRadiusMiles,
    } = dispensary || {};

    return deliveryZoneRangeType === "postal_code"
      ? !!deliveryZonePostalCodeWhitelist?.length
      : !!deliveryRadiusMiles && deliveryRadiusMiles > 0;
  },
);

export const getAcceptsOrders = createSelector(
  [getCurrentDispensary, getWithinDeliveryRange],
  (dispensary, withinDeliveryRange) => {
    const { hasReservationsEnabled, hasDeliveryEnabled } = dispensary || {};

    return (
      hasReservationsEnabled || (hasDeliveryEnabled && withinDeliveryRange)
    );
  },
);

export const getDispensaryUtm = createSelector(
  [getCurrentDispensary],
  (dispensary) => {
    const { website } = dispensary || {};
    if (!website) {
      return null;
    }

    return (
      website +
      "?utm_source=leafly.com&utm_medium=referral&utm_campaign=visit-website-link"
    );
  },
);

export const getLocalizedDistanceForCurrentDispensary = createSelector(
  [getDomainCountryCode, getCurrentDispensary, getCoordinates],
  getLocalizedDistanceAnyDispensary,
);

export const getIsDeliveryOnly = createSelector(
  [getCurrentDispensary],
  (dispensary) => {
    const tags = dispensary?.tags || [];
    return tags.includes("delivery") && !tags.includes("storefront");
  },
);

export const getPrimaryAddress = createSelector(
  [getCurrentDispensary],
  (dispensary): string => {
    if (!dispensary) {
      return "";
    }

    return [
      dispensary.address1,
      dispensary.address2,
      dispensary.city,
      dispensary.state,
    ]
      .filter((addressPart) => !!addressPart)
      .join(", ");
  },
);

export const getGoogleMapsUrl = createSelector(
  [getCurrentDispensary, getPrimaryAddress],
  (dispensary, primaryAddress) => {
    const { name, address1, address2 } = dispensary || {};

    if (address1 || address2) {
      return buildGoogleMapsUrl({ name, primaryAddress });
    } else {
      return null;
    }
  },
);

export const getGoogleMapsDirectionsUrl = createSelector(
  [getCurrentDispensary, getPrimaryAddress],
  (dispensary, primaryAddress) => {
    const { name, address1, address2 } = dispensary || {};

    if (address1 || address2) {
      return buildGoogleMapsUrl({ name, primaryAddress, withDirections: true });
    } else {
      return null;
    }
  },
);

export const getIsUnclaimed = createSelector(
  [getCurrentDispensary],
  (dispensary) =>
    dispensary &&
    dispensary.featureTier === "info" &&
    dispensary.retailType === "clinic" &&
    dispensary.country === "US",
);

export const getReviewCount = createSelector(
  [getCurrentDispensary],
  (dispensary) => {
    return dispensary?.reviewCount || 0;
  },
);

export const getRoundedRating = createSelector(
  [getCurrentDispensary],
  (dispensary) => {
    return dispensary?.roundedRating || 0;
  },
);

export const getIsDualMenu = createSelector(
  [getCurrentDispensary],
  (dispensary) => dispensary?.tags?.includes("dualLicense"),
);

export const getHasFollowed = createSelector(
  [getDispensary],
  (dispensary) => dispensary.followed,
);

export const getIsCanadianDispensary = createSelector(
  [getCurrentDispensary],
  (dispensary) => dispensary.country === "CA",
);

export const getDispensaryScheduleStatuses = createSelector(
  [getCurrentDispensary],
  (dispensary) => {
    const { currentStatuses, preorderConfigured, timeZone } = dispensary || {};
    const shortenedTimeZone = getShortenedTimeZone(timeZone);

    return {
      deliveryStatus: currentStatuses?.delivery?.isOpen ? "open" : "closed",
      deliveryStatusMessage: getStatusMessage(
        preorderConfigured,
        currentStatuses?.delivery,
        timeZone,
        "delivery",
      ),
      isStoreOpen: currentStatuses?.store?.isOpen,
      pickupStatus: currentStatuses?.pickup?.isOpen ? "open" : "closed",
      pickupStatusMessage: getStatusMessage(
        preorderConfigured,
        currentStatuses?.pickup,
        timeZone,
        "pickup",
      ),
      preorderStatus: currentStatuses?.preorder?.isOpen ? "open" : "closed",
      shortenedTimeZone,
      storeStatus: currentStatuses?.store?.isOpen ? "open" : "closed",
      storeStatusMessage: getStatusMessage(
        preorderConfigured,
        currentStatuses?.store,
        timeZone,
        "store",
      ),
    };
  },
);

export const getCurrentDispensaryIsSingleLicensed = createSelector(
  [getDispensary],
  (dispensary) => !dispensary.currentDispensary.tags.includes("dualLicense"),
);
