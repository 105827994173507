import { createSelector } from "@reduxjs/toolkit";

import { CartItem } from "custom-types/Cart";
import { MedicalIdCondition } from "custom-types/MedicalIdCondition";
import { RootState } from "redux/reducers/rootReducer";
import { consumeCartTotals } from "utils/calculateCartTotals";
import {
  getIsPickup,
  normalizeCartContextAddress,
} from "utils/cartContextUtils";
import currencyFormatter from "utils/currencyFormatter";

import {
  getCartDispensaryState,
  getCurrentStatusIsClosed,
  getCurrentStatusIsOpenForDelivery,
  getCurrentStatusIsOpenForPickupOrPreorder,
  getDeliveryFeeWithMinumSpend,
  getDeliveryOrderMinimum,
  getDispensary,
  getIsDispensaryDualLicense,
  getOnlyDeliveryIsOpen,
} from "./cartDispensary";
import { getDeliveryAddress, getIsValidDeliveryAddress } from "./location";
import { getUserFulfillmentPreference } from "./user";

export const getCart = (state: RootState) => state.cart;

export const getCartSessionId = createSelector(
  [getCart],
  (state) => state.sessionId,
);

export const getCartChanges = (state: RootState) => state.cart?.changes || [];

export const getSelectedForDeliveryOption = (state: RootState) => {
  if (state.cart?.data?.convertible) {
    return false;
  }
  return state.cart?.selectedForDeliveryOption || false;
};

export const getTwilioPhoneNumberSelector = (state: RootState) =>
  state.cart.twilioPhoneNumber;

export const showSmsOptInMessageSelector = (state: RootState) =>
  state.cart.showSmsOptInMessage;

export const getCartIsInStore = createSelector([getCart], (cart) => {
  return cart?.data?.convertible || false;
});

export const getCartItems = createSelector([getCart], (cart) => {
  return cart?.data?.cartItems
    ? cart.data.cartItems.sort(
        (a: { id: number }, b: { id: number }) => a.id - b.id,
      )
    : [];
});

export const getCartTotal = createSelector([getCart], (cart) => {
  return consumeCartTotals(cart.data);
});

export const getCartTotalDeliveryWithMinimumSpend = createSelector(
  [getCart, getDeliveryFeeWithMinumSpend],
  (cart, deliveryFee) => {
    return consumeCartTotals(cart.data, Number(deliveryFee) || 0);
  },
);

export const getCartTotalByOrderTypeSelector =
  (state: RootState) => (forDelivery: boolean) => {
    if (forDelivery) {
      return getCartTotalDeliveryWithMinimumSpend(state);
    }
    return getCartTotal(state);
  };

export const getDealGroups = createSelector([getCart], (cart) => {
  return cart?.data?.dealGroups || [];
});

export const getDeliveryMinimumCartInfo = createSelector(
  [getCartTotal, getDeliveryOrderMinimum],
  ({ subtotal, savings }, deliveryMin) => {
    const shortFallAsNumber =
      Number(deliveryMin) - (Number(subtotal) - Number(savings));
    const formattedShortfall = currencyFormatter(shortFallAsNumber, "US");
    return {
      deliveryMin,
      deliveryMinFormatted: currencyFormatter(deliveryMin, "US"),
      deliveryMinShortfallFormatted: formattedShortfall,
      hasMetMin: shortFallAsNumber <= 0,
    };
  },
);

export const getTotalCartQuantity = createSelector([getCart], (cart) => {
  return cart?.data?.cartItems.reduce(
    (count, item) => count + item.quantity || 0,
    0,
  );
});

export const getIsCartMedical = createSelector(
  [getCartItems],
  (cartItems: CartItem[]) => cartItems.some((item) => item.medical),
);

export const getIsCartRecreational = createSelector(
  [getIsCartMedical],
  (hasMedicalVariants) => !hasMedicalVariants,
);

export const getCartTypeLabel = createSelector(
  [getIsCartMedical, getIsDispensaryDualLicense],
  (hasMedicalVariants, isDualLicense) => {
    if (!isDualLicense) {
      return null;
    }
    return hasMedicalVariants ? "MEDICAL" : "RECREATIONAL";
  },
);

export const getCheckoutBlockedReason = createSelector(
  [
    getCartDispensaryState,
    getCartItems,
    getCurrentStatusIsClosed,
    getDeliveryMinimumCartInfo,
    getOnlyDeliveryIsOpen,
  ],
  (
    dispensary,
    cartItems,
    isCompletelyClosed,
    deliveryMinimumCartInfo,
    onlyDeliveryIsOpen,
  ) => {
    if (!dispensary || !dispensary.data) {
      return "no_dispensary";
    }
    if (!cartItems || !cartItems.length) {
      return "no_cart_items";
    }
    if (onlyDeliveryIsOpen && !deliveryMinimumCartInfo.hasMetMin) {
      return "delivery_min_not_met";
    }
    if (isCompletelyClosed) {
      return "dispensary_closed";
    }
    return "";
  },
);

export const getCurrentStatusCheckoutBlockedReason = createSelector(
  [
    getCartDispensaryState,
    getCartItems,
    getCurrentStatusIsClosed,
    getDeliveryMinimumCartInfo,
    getOnlyDeliveryIsOpen,
    getCartIsInStore,
  ],
  (
    dispensary,
    cartItems,
    isCompletelyClosed,
    deliveryMinimumCartInfo,
    onlyDeliveryIsOpen,
    cartIsInStore,
  ) => {
    if (!dispensary || !dispensary.data) {
      return "no_dispensary";
    }
    if (!cartItems || !cartItems.length) {
      return "no_cart_items";
    }
    if (cartIsInStore) {
      return "convertible_cart";
    }
    if (onlyDeliveryIsOpen && !deliveryMinimumCartInfo.hasMetMin) {
      return "delivery_min_not_met";
    }
    if (isCompletelyClosed) {
      return "dispensary_closed";
    }
    return "";
  },
);

export const getCartContext = createSelector(
  [
    getDeliveryAddress,
    getIsValidDeliveryAddress,
    getCurrentStatusIsOpenForPickupOrPreorder,
    getCurrentStatusIsOpenForDelivery,
    getUserFulfillmentPreference,
    getCartIsInStore,
  ],
  (
    deliveryAddress,
    isValidAddress,
    openForPickupOrPreorder,
    openForDelivery,
    userFulfillmentPreference,
    cartIsInStore,
  ) => {
    let normalizedDeliveryAddress = null;

    const isPickup = getIsPickup(
      openForPickupOrPreorder as boolean,
      openForDelivery,
      userFulfillmentPreference,
    );

    if (deliveryAddress && isValidAddress && !isPickup) {
      normalizedDeliveryAddress = normalizeCartContextAddress(deliveryAddress);
    }

    return {
      deliveryAddress: normalizedDeliveryAddress,
      fulfillmentMechanism: isPickup || cartIsInStore ? "pickup" : "delivery",
    };
  },
);

export const getCanProceedToCheckout = createSelector(
  [
    getCartDispensaryState,
    getCartItems,
    getCurrentStatusIsClosed,
    getDeliveryMinimumCartInfo,
    getSelectedForDeliveryOption,
    getCurrentStatusIsOpenForDelivery,
  ],
  (
    dispensary,
    cartItems,
    isCompletelyClosed,
    deliveryMinimumCartInfo,
    selectedForDelivery,
    dispensaryIsOpenForDelivery,
  ) => {
    if (!dispensary || !dispensary.data) {
      return false;
    }
    if (!cartItems || !cartItems.length) {
      return false;
    }
    if (isCompletelyClosed) {
      return false;
    }
    if (selectedForDelivery) {
      if (!dispensaryIsOpenForDelivery) {
        return false;
      }
      if (!deliveryMinimumCartInfo.hasMetMin) {
        return false;
      }
      if (!dispensary.deliveryServiceArea) {
        return false;
      }
    }
    return true;
  },
);

export const getCurrentStatusCanProceedToCheckout = createSelector(
  [
    getCartDispensaryState,
    getCartItems,
    getCurrentStatusIsClosed,
    getDeliveryMinimumCartInfo,
    getSelectedForDeliveryOption,
    getCurrentStatusIsOpenForDelivery,
  ],
  (
    dispensary,
    cartItems,
    isCompletelyClosed,
    deliveryMinimumCartInfo,
    selectedForDelivery,
    dispensaryIsOpenForDelivery,
  ) => {
    if (!dispensary || !dispensary.data) {
      return false;
    }
    if (!cartItems || !cartItems.length) {
      return false;
    }
    if (isCompletelyClosed) {
      return false;
    }
    if (selectedForDelivery) {
      if (!dispensaryIsOpenForDelivery) {
        return false;
      }
      if (!deliveryMinimumCartInfo.hasMetMin) {
        return false;
      }
      if (!dispensary.deliveryServiceArea) {
        return false;
      }
    }
    return true;
  },
);

export const getOrderMedIdCondition = createSelector(
  [getDispensary, getCartItems, getSelectedForDeliveryOption],
  (
    dispensary: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
      tags?: any;
      state: string;
      orderMedIdCondition: string;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    cartItems: any[],
    hasSelectedDelivery: boolean,
  ) => {
    const { state, orderMedIdCondition } = dispensary;

    // ! MORE TEMP HACKS HERE
    // TODO Remove this check when Arizona Rec Delivery becomes legal.
    if (state === "AZ" && hasSelectedDelivery) {
      return MedicalIdCondition.Required;
    }

    const hasMedicalVariantsInCart = cartItems.some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
      (item: { medical: any }) => item.medical,
    );
    const isMedicalDispensary = dispensary.tags?.includes("medical");
    const isRecreationalDispensary = dispensary.tags?.includes("recreational");

    // in the event a store is tagged with both, requiring med id would block
    // recreational customers from ordering, so we need to make it optional.
    if (
      isMedicalDispensary &&
      isRecreationalDispensary &&
      orderMedIdCondition === MedicalIdCondition.Required
    ) {
      return MedicalIdCondition.Optional;
    }

    return hasMedicalVariantsInCart || isMedicalDispensary
      ? orderMedIdCondition
      : MedicalIdCondition.NotAccepted;
  },
);

export const getCartErrors = (state: RootState) => state.cart?.error || [];

export const getSelectedScheduleWindow = createSelector(
  [getCart],
  (state) => state.selectedScheduleWindow,
);

export const getLastItemAddedToCart = createSelector([getCart], (state) =>
  state.justAddedVariantId
    ? state.data?.cartItems?.find(
        (ci) => ci.variantId === state.justAddedVariantId,
      )
    : undefined,
);
