import { setCookie } from "cookies-next";
import type { IncomingMessage, ServerResponse } from "http";

import type { GeolocationResult } from "api/requests/getGeolocation";
import publicConfig from "config/public";
import type { decryptPlaceIdToLocation } from "utils/addressEncryption";

/**
 * Note: Eventually, this module should be rewritten to use Next.js middleware,
 * but as long as we are using a custom server that isn't well supported.
 */

/**
 * This check for window ensures that we're appropriatly tree shaking to avoid
 * adding the crypto parts of node to our client-side bundle.
 *
 * This won't be necessary when this functionality is refactored into next
 * middleware.
 */
const decryptPlaceIdToLocationImport: Promise<typeof decryptPlaceIdToLocation> =
  typeof window === "undefined"
    ? import("../../utils/addressEncryption").then(
        ({ decryptPlaceIdToLocation }) => decryptPlaceIdToLocation,
      )
    : Promise.resolve(() => Promise.resolve(undefined));

const decryptPlaceId = (token: string) =>
  decryptPlaceIdToLocationImport.then((f) => f(token));

/**
 * This middleware converts and encrypted address token (created using the
 * /api/address-transfer) endpoing into a location object. This location object
 * is set in the leafly-location cookie, and then the browser is redirected to
 * the URL without the "address-token" query parameter.
 *
 * This process allows native applications to transfer an address that has
 * been set in the native application to a web view.
 */
export default async function addressTokenMiddleware(
  req: IncomingMessage,
  res: ServerResponse,
) {
  if (!req.url) {
    return;
  }

  const { cookieDomain, cookieDomainCa } = publicConfig;
  const host = req.headers.host;

  if (!host) {
    return;
  }

  const protocol = host.includes("localhost") ? "http" : "https";
  const url = new URL(req.url, `${protocol}://${host}`);
  const token = url.searchParams.get("address-token");

  if (token) {
    let location: GeolocationResult | undefined;

    try {
      location = await decryptPlaceId(token);
    } catch (e) {
      // Something went wrong decrypting the token. Likely invalid.
    }

    // set cookie
    if (location) {
      setCookie("leafly-location", JSON.stringify(location), {
        domain: url.host.includes(".ca") ? cookieDomainCa : cookieDomain,
        httpOnly: false,
        maxAge: 86400 * 365 * 1000,
        req,
        res,
      });
    }

    // remove address-token from query
    url.searchParams.delete("address-token");

    // combine path and query (preserving other query params if they exist)
    res.statusCode = 302;
    res.setHeader("Location", String(url));
    res.end();
    return true;
  } else {
    return false;
  }
}
