import React from "react";
import Link from "next/link";

import { ResponsiveAccordion } from "./ResponsiveAccordion";
import { useFooterClickEventTracker } from "./useFooterClickEventTracker";

export const AboutLeafly: React.FC<{ isResponsive?: boolean }> = ({
  isResponsive,
}) => {
  const { createPublishFooterClickEventFunction } =
    useFooterClickEventTracker();

  return (
    <ResponsiveAccordion
      label="About Leafly"
      defaultExpanded={false}
      isResponsive={isResponsive}
    >
      <ul className="text-sm text-green font-bold">
        <li className="py-sm">
          <Link
            href="/info/about"
            onClick={createPublishFooterClickEventFunction("about us")}
          >
            About us
          </Link>
        </li>
        <li className="py-sm">
          <Link
            href="/info/jobs"
            onClick={createPublishFooterClickEventFunction("careers")}
          >
            Careers
          </Link>
        </li>
        <li className="py-sm">
          <a
            href="https://www.leafly.com/newsroom"
            onClick={createPublishFooterClickEventFunction("newsroom")}
          >
            Newsroom
          </a>
        </li>
        <li className="py-sm">
          <a
            href="https://investor.leafly.com"
            onClick={createPublishFooterClickEventFunction(
              "investor relations",
            )}
          >
            {" "}
            Investor relations
          </a>
        </li>
        <li className="py-sm">
          <Link
            href="/info/contact"
            onClick={createPublishFooterClickEventFunction("contact us")}
          >
            Contact us
          </Link>
        </li>
        <li className="py-sm">
          <a
            href="https://help.leafly.com"
            onClick={createPublishFooterClickEventFunction("faqs")}
          >
            FAQs
          </a>
        </li>
        <li className="py-sm mr-md">
          <Link
            href="/info/accessibility"
            onClick={createPublishFooterClickEventFunction("accessibility")}
          >
            Accessibility
          </Link>
        </li>
      </ul>
    </ResponsiveAccordion>
  );
};
