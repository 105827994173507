import { AllActions } from "./rootReducer";

type ConfigState = {
  domainCountryCode: string;
};

export const initialState: ConfigState = { domainCountryCode: "US" };

// This is a special action that any reducer can respond to, similar to load cookies
export const SET_DOMAIN_LOCATION = "config/domain/set";

export type SetDomainCountryCodeAction = {
  type: typeof SET_DOMAIN_LOCATION;
  countryCode: string;
};

export type ConfigActions = SetDomainCountryCodeAction;

const configReducer = (
  state = initialState,
  action: AllActions,
): ConfigState => {
  switch (action.type) {
    case SET_DOMAIN_LOCATION:
      return {
        ...state,
        domainCountryCode: action.countryCode,
      };
    default:
      return state;
  }
};

export default configReducer;
