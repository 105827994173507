import { DeliveryZoneDecisionResponse } from "custom-types/Compliance";
import { Dispensary } from "custom-types/Dispensary";

import { AllActions } from "./rootReducer";

export type DispensaryState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  currentDispensary?: any;
  deliveryZone?: DeliveryZoneDecisionResponse | null;
  followed: boolean;
  loaded: boolean;
};

export const initialState: DispensaryState = {
  currentDispensary: null,
  deliveryZone: null,
  followed: false,
  loaded: false,
};

export const DISPENSARY_LOAD = "dispensary/load";
export const SET_DELIVERY_ZONE = "dispensary/setDeliveryZone";
export const DISPENSARY_FOLLOW = "dispensary/follow";
export const DISPENSARY_UNFOLLOW = "dispensary/unfollow";

export type DispensaryLoadAction = {
  type: typeof DISPENSARY_LOAD;
  dispensary: Dispensary | null;
};

export type SetDeliveryZoneAction = {
  type: typeof SET_DELIVERY_ZONE;
  deliveryZone: DeliveryZoneDecisionResponse | null;
};

export type DispensaryFollowAction = {
  type: typeof DISPENSARY_FOLLOW;
};

export type DispensaryUnfollowAction = {
  type: typeof DISPENSARY_UNFOLLOW;
};

export type DispensaryActions =
  | DispensaryLoadAction
  | SetDeliveryZoneAction
  | DispensaryFollowAction
  | DispensaryUnfollowAction;

const dispensaryReducer = (
  state = initialState,
  action: AllActions,
): DispensaryState => {
  switch (action.type) {
    case DISPENSARY_LOAD:
      return {
        ...state,
        currentDispensary: {
          ...action.dispensary,
        },
        loaded: true,
      };
    case SET_DELIVERY_ZONE:
      return {
        ...state,
        deliveryZone: action.deliveryZone,
      };
    case DISPENSARY_FOLLOW:
      return {
        ...state,
        followed: true,
      };
    case DISPENSARY_UNFOLLOW:
      return {
        ...state,
        followed: false,
      };
    default:
      return state;
  }
};

export default dispensaryReducer;
