import { useSelector } from "react-redux";

import publicConfig from "config/public";
import { CartDispensary } from "custom-types/CartDispensary";
import { getCartDispensaryData } from "redux/selectors/cartDispensary";
import { getCurrentDispensary } from "redux/selectors/dispensary";
import logError from "utils/logError";

export const shouldUseJaneCheckout = (dispensary: CartDispensary) => {
  return dispensary?.isJaneRetailer && dispensary?.hasJaneCheckoutEnabled;
};

export const useIsJaneCheckoutEnabled = () => {
  const { janeCheckoutUrl } = publicConfig;

  const cartDispensary = useSelector(getCartDispensaryData);
  const menuDispensary = useSelector(getCurrentDispensary);
  const dispensary = cartDispensary || menuDispensary;
  const useJaneCheckout = dispensary && shouldUseJaneCheckout(dispensary);
  try {
    const isJaneCheckoutEnabled = Boolean(janeCheckoutUrl && useJaneCheckout);
    return isJaneCheckoutEnabled;
  } catch {
    logError("Error determining isJaneCheckoutEnabled", {
      functionName: "useIsJaneCheckoutEnabled",
    });
    return false;
  }
};
