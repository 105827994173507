import React from "react";

import { useFooterClickEventTracker } from "./useFooterClickEventTracker";

export const AppStoreButtons = () => {
  const { createPublishFooterClickEventFunction } =
    useFooterClickEventTracker();

  return (
    <>
      <div className="flex justify-center items-center mb-lg">
        <img
          className="lazyload mr-lg"
          height={96}
          width={96}
          alt="Leafly mobile app"
          data-src="https://public.leafly.com/cephalopod/images/mobile-app.svg"
        />
        <div>
          <div className="text-md font-bold">Get high for less.</div>
          <div className="text-sm">Download the Leafly app.</div>
        </div>
      </div>

      <div className="flex justify-center gap-4">
        <a
          href="https://apps.apple.com/app/apple-store/id416456429?pt=452354&ct=Leafly%20Footer&mt=8"
          title="Leafly on the App Store"
          onClick={createPublishFooterClickEventFunction("app store")}
        >
          <img
            className="lazyload"
            height={44}
            width={142}
            alt="Download Leafly: Marijuana Reviews on the App Store"
            data-src="https://public.leafly.com/cephalopod/images/app-store.svg"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=leafly.android&referrer=utm_source%3Dleafly%26utm_medium%3Dreferral%26utm_campaign%3Dfooter"
          title="Leafly on Google Play"
          onClick={createPublishFooterClickEventFunction("google play")}
        >
          <img
            className="lazyload"
            height={44}
            width={156}
            alt="Download Leafly Marijuana Reviews on Google Play"
            data-src="https://public.leafly.com/cephalopod/images/google-play.svg"
          />
        </a>
      </div>
    </>
  );
};
