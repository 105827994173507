import React from "react";
import Link from "next/link";

import useDomainCountryCode from "hooks/useDomainCountryCode";

import { ResponsiveAccordion } from "./ResponsiveAccordion";
import { useFooterClickEventTracker } from "./useFooterClickEventTracker";

export const DispensaryQuickLinks: React.FC<{ isResponsive?: boolean }> = ({
  isResponsive,
}) => {
  const isCanada = useDomainCountryCode() === "CA";
  const { createPublishFooterClickEventFunction } =
    useFooterClickEventTracker();

  return (
    <ResponsiveAccordion
      label={isCanada ? "Stores in" : "Dispensaries in"}
      defaultExpanded={false}
      isResponsive={isResponsive}
    >
      <ul className="font-medium text-sm text-green font-bold">
        {isCanada ? (
          <>
            <li className="py-sm">
              <Link
                href={`/dispensaries/british-columbia`}
                onClick={createPublishFooterClickEventFunction(
                  "british columbia stores",
                )}
              >
                British Columbia
              </Link>
            </li>
            <li className="py-sm">
              <Link
                href={`/dispensaries/ontario`}
                onClick={createPublishFooterClickEventFunction(
                  "ontario stores",
                )}
              >
                Ontario
              </Link>
            </li>
            <li className="py-sm">
              <Link
                href={`/dispensaries/alberta`}
                onClick={createPublishFooterClickEventFunction(
                  "alberta stores",
                )}
              >
                Alberta
              </Link>
            </li>
            <li className="py-sm">
              <Link
                href={`/dispensaries/saskatchewan`}
                onClick={createPublishFooterClickEventFunction(
                  "saskatchewan stores",
                )}
              >
                Saskatchewan
              </Link>
            </li>
            <li className="py-sm">
              <Link
                href={`/dispensaries/manitoba`}
                onClick={createPublishFooterClickEventFunction(
                  "manitoba stores",
                )}
              >
                Manitoba
              </Link>
            </li>
            <li className="py-sm">
              <Link
                href={`/dispensaries/prince-edward-island`}
                onClick={createPublishFooterClickEventFunction(
                  "prince edwards island stores",
                )}
              >
                Prince Edward Island
              </Link>
            </li>
          </>
        ) : (
          <>
            <li className="py-sm">
              <Link
                href={`/dispensaries/california/los-angeles`}
                onClick={createPublishFooterClickEventFunction(
                  "los angeles dispensaries",
                )}
              >
                Los Angeles
              </Link>
            </li>
            <li className="py-sm">
              <Link
                href={`/dispensaries/washington/seattle`}
                onClick={createPublishFooterClickEventFunction(
                  "seattle dispensaries",
                )}
              >
                Seattle
              </Link>
            </li>
            <li className="py-sm">
              <Link
                href={`/dispensaries/oregon/portland`}
                onClick={createPublishFooterClickEventFunction(
                  "portland dispensaries",
                )}
              >
                Portland
              </Link>
            </li>
            <li className="py-sm">
              <Link
                href={`/dispensaries/california/san-francisco`}
                onClick={createPublishFooterClickEventFunction(
                  "san francisco dispensaries",
                )}
              >
                San Francisco
              </Link>
            </li>
            <li className="py-sm">
              <Link
                href="https://leafly.ca/dispensaries/ontario/toronto"
                onClick={createPublishFooterClickEventFunction(
                  "toronto dispensaries",
                )}
              >
                Toronto
              </Link>
            </li>
            <li className="py-sm">
              <Link
                href={`/dispensaries/michigan/detroit`}
                onClick={createPublishFooterClickEventFunction(
                  "detroit dispensaries",
                )}
              >
                Detroit
              </Link>
            </li>
          </>
        )}
      </ul>
    </ResponsiveAccordion>
  );
};
