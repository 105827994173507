import axios, { type AxiosError, type AxiosRequestHeaders } from "axios";

import publicConfig from "config/public";
import formatAndReturnRejectedError from "utils/formatAndReturnRejectedError";

const {
  services: { directusApi },
  serviceRequest,
} = publicConfig;

const { url, token } = directusApi;

export const directusErrorInterceptor = (
  error: AxiosError<{
    errors: {
      message: string;
      extensions: { code: number; errors: { message: string }[] };
    }[];
  }>,
) =>
  formatAndReturnRejectedError(
    error,
    error.response?.data?.errors?.length
      ? error.response.data.errors[0].extensions?.errors?.length
        ? error.response.data.errors[0].extensions?.errors[0].message
        : error.response.data.errors[0].message
      : undefined,
    error.response?.data?.errors?.length
      ? error.response.data.errors[0].extensions?.code
      : undefined,
  );

const headers: AxiosRequestHeaders = {
  Authorization: `bearer ${token}`,
};

if (typeof window === "undefined") {
  headers["User-Agent"] = `web-web / ${serviceRequest.headers.environment}`;
}

const directus = axios.create({
  baseURL: `${url}/items/`,
  headers,
  timeout: 5_000,
});

directus.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

directus.interceptors.response.use(
  (response) => response?.data,
  directusErrorInterceptor,
);

export default directus;
