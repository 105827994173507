import { useDispatch, useSelector } from "react-redux";

import { useEvent } from "hooks/useEvent";
import { RootState } from "redux/reducers/rootReducer";
import {
  Toast,
  TOASTS_DISMISS,
  TOASTS_NOTIFY,
  ToastType,
} from "redux/reducers/toasts";

/**
 * Toast alerts
 *
 * Example usage:
 *
 * const { notify, dismiss } = useToast();
 * const id = notify("A toast", ToastType.Error);
 * dismiss(id);
 */
export const useToast = (): {
  dismiss: (id: string) => void;
  instances: Toast[];
  notify: (message: string | JSX.Element, type: ToastType) => string;
} => {
  const dispatch = useDispatch();
  const instances = useSelector((state: RootState) => state.toasts.toasts);
  const dismiss = useEvent((id: string) => {
    dispatch({ type: TOASTS_DISMISS, value: id });
  });
  const notify = useEvent((message: string | JSX.Element, type: ToastType) => {
    const id = Math.random().toString(36).substring(7);
    dispatch({ type: TOASTS_NOTIFY, value: { id, message, type } });
    return id;
  });

  return {
    dismiss,
    instances,
    notify,
  };
};
