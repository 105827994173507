import { toKm } from "@leafly-com/web-utils";

import { Coordinates } from "custom-types/Coordinates";
import { Dispensary } from "custom-types/Dispensary";
import { getMilesBetween } from "utils/distance";
import logError from "utils/logError";

/*
 * This file is for functions that make it easier to work with Dispensary objects.
 * These are not selectors because they do not take state objects as input and are not memoized.
 */

const getMilesFromAnyDispensary = (
  dispensary?: Dispensary | null,
  userLocation?: Coordinates | null,
) => {
  const { distanceMi, primaryLocation } = dispensary || {};

  if (distanceMi) {
    return distanceMi.toFixed(1);
  } else if (primaryLocation && userLocation) {
    try {
      return getMilesBetween(userLocation, primaryLocation, "miles")?.toFixed(
        1,
      );
    } catch {
      logError("Error processing miles", {
        functionName: "getMilesFromAnyDispensary",
      });
      return null;
    }
  }

  return null;
};

const convertToKilometers = (miles?: string | number | null) =>
  toKm(miles)?.toFixed(1);
const getLocalizedDistance = (
  miles: string | number | null,
  km: string | number,
  countryCode: string,
) => {
  if (!miles) {
    return {
      full: null,
      shortened: null,
    };
  }
  return {
    full: countryCode === "CA" ? `${km} km away` : `${miles} miles away`,
    shortened: countryCode === "CA" ? `${km} km` : `${miles} mi`,
  };
};

export const getLocalizedDistanceAnyDispensary = (
  countryCode: string,
  dispensary?: Dispensary | null,
  location?: Coordinates | null,
) => {
  const miles = getMilesFromAnyDispensary(dispensary, location);
  const km = convertToKilometers(miles);
  return getLocalizedDistance(miles, km, countryCode);
};

export const buildGoogleMapsUrl = ({
  name,
  primaryAddress,
  withDirections,
}: {
  name: string;
  primaryAddress: string;
  withDirections?: boolean;
}) => {
  const namePlusLocation = [encodeURIComponent(name), primaryAddress]
    .join(" ")
    .replace(/\s/g, "+");

  return withDirections
    ? `https://www.google.com/maps/dir/?api=1&destination=${namePlusLocation}`
    : `https://maps.google.com/?q=${namePlusLocation}`;
};
