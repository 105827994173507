import { AllActions } from "./rootReducer";

export type SearchSuggestion = {
  distanceMi?: number;
  iconFilePath: string;
  id?: number;
  link: string;
  title: string;
  subtitle?: string;
};

type HeaderState = {
  collapsed: boolean;
  loadingSpinner: {
    reasons: Array<string>;
  };
  navigationOpen: boolean;
  notificationCount?: number;
  reloadPageAfterLocationChange: boolean;
  search: {
    open: boolean;
    recentSearches: Array<SearchSuggestion>;
    suggestions: Array<SearchSuggestion>;
  };
};

export const initialState: HeaderState = {
  collapsed: false,
  loadingSpinner: {
    reasons: [],
  },
  navigationOpen: false,
  reloadPageAfterLocationChange: true,
  search: {
    open: false,
    recentSearches: [],
    suggestions: [],
  },
};

export const OPEN_NAVIGATION = "header/openNavigation";
export const CLOSE_NAVIGATION = "header/closeNavigation";
export const EXPAND_HEADER = "header/expand";
export const COLLAPSE_HEADER = "header/collapse";
export const OPEN_SEARCH = "header/openSearch";
export const CLOSE_SEARCH = "header/closeSearch";
export const SUBMIT_SEARCH = "header/submitSearch";
export const UPDATE_SEARCH_SUGGESTIONS = "header/updateSearchSuggestions";
export const UPDATE_NOTIFICATION_COUNT = "header/updateNotificationCount";
export const ENABLE_LOADING_SPINNER = "header/enableLoadingSpinner";
export const DISABLE_LOADING_SPINNER = "header/disableLoadingSpinner";
export const DISABLE_LOCATION_CHANGE_RELOAD =
  "header/disableLocationChangeReload";
export const ENABLE_LOCATION_CHANGE_RELOAD =
  "header/enableLocationChangeReload";

export type OpenNavigation = {
  type: typeof OPEN_NAVIGATION;
};

export type CloseNavigation = {
  type: typeof CLOSE_NAVIGATION;
};

export type ExpandHeader = {
  type: typeof EXPAND_HEADER;
};

export type CollapseHeader = {
  type: typeof COLLAPSE_HEADER;
};

export type OpenSearch = {
  type: typeof OPEN_SEARCH;
  searchText: string;
  storage?: Storage;
};

export type CloseSearch = {
  type: typeof CLOSE_SEARCH;
};

export type SubmitSearch = {
  type: typeof SUBMIT_SEARCH;
  searchText: string;
  storage?: Storage;
};

export type UpdateSearchSuggestions = {
  type: typeof UPDATE_SEARCH_SUGGESTIONS;
  suggestions: Array<SearchSuggestion>;
};

export type UpdateNotificationCount = {
  type: typeof UPDATE_NOTIFICATION_COUNT;
  count: number;
};

export type EnableLoadingSpinner = {
  type: typeof ENABLE_LOADING_SPINNER;
  reason: string;
};

export type DisableLoadingSpinner = {
  type: typeof DISABLE_LOADING_SPINNER;
  reason?: string;
};

export type EnableLocationChangeReload = {
  type: typeof ENABLE_LOCATION_CHANGE_RELOAD;
};

export type DisableLocationChangeReload = {
  type: typeof DISABLE_LOCATION_CHANGE_RELOAD;
};

export type HeaderActions =
  | OpenNavigation
  | CloseNavigation
  | ExpandHeader
  | CollapseHeader
  | OpenSearch
  | CloseSearch
  | UpdateSearchSuggestions
  | SubmitSearch
  | UpdateNotificationCount
  | EnableLoadingSpinner
  | DisableLoadingSpinner
  | EnableLocationChangeReload
  | DisableLocationChangeReload;

const getRecentSearchHistory = (storage?: Storage): Array<SearchSuggestion> => {
  const recentSearches = storage?.getItem("recentSearches")?.split(",");

  if (recentSearches) {
    return recentSearches.map((rs) => ({
      iconFilePath: "refresh.svg",
      link: "/search?q=" + encodeURIComponent(rs),
      title: rs,
    }));
  }

  return [];
};

const MAX_RECENT_SEARCH_ITEMS = 7;
const purgeBadCharacters = (s?: string) =>
  s?.replace(/[^a-zA-Z0-9\s\-.'&]/g, "");
const updateRecentSearchHistory = (searchText: string, storage?: Storage) => {
  const recentSearches =
    storage
      ?.getItem("recentSearches")
      ?.split(",")
      .slice(0, MAX_RECENT_SEARCH_ITEMS) || [];

  const sanitizedSearchText = purgeBadCharacters(searchText);
  if (
    sanitizedSearchText &&
    recentSearches.indexOf(sanitizedSearchText) === -1
  ) {
    storage?.setItem(
      "recentSearches",
      [sanitizedSearchText, ...recentSearches].join(","),
    );
  }
};

const reducer = (
  state: HeaderState = initialState,
  action: AllActions,
): HeaderState => {
  switch (action.type) {
    case OPEN_NAVIGATION:
      return {
        ...state,
        navigationOpen: true,
      };
    case CLOSE_NAVIGATION:
      return {
        ...state,
        navigationOpen: false,
      };
    case COLLAPSE_HEADER:
      return {
        ...state,
        collapsed: true,
      };
    case EXPAND_HEADER:
      return {
        ...state,
        collapsed: false,
      };
    case OPEN_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          open: true,
          recentSearches: getRecentSearchHistory(action.storage),
          suggestions:
            action.searchText?.length > 2 ? state.search.suggestions : [],
        },
      };
    case CLOSE_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          open: false,
        },
      };
    case UPDATE_SEARCH_SUGGESTIONS:
      return {
        ...state,
        search: {
          ...state.search,
          suggestions: action.suggestions || [],
        },
      };
    case SUBMIT_SEARCH:
      updateRecentSearchHistory(action.searchText, action.storage);
      return initialState;
    case UPDATE_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.count,
      };
    case ENABLE_LOADING_SPINNER:
      return {
        ...state,
        loadingSpinner: {
          reasons: [...state.loadingSpinner.reasons, action.reason],
        },
      };
    case DISABLE_LOADING_SPINNER:
      return {
        ...state,
        loadingSpinner: {
          reasons: state.loadingSpinner.reasons.filter(
            (r) => action.reason && r !== action.reason,
          ),
        },
      };
    case ENABLE_LOCATION_CHANGE_RELOAD:
      return {
        ...state,
        reloadPageAfterLocationChange: true,
      };
    case DISABLE_LOCATION_CHANGE_RELOAD:
      return {
        ...state,
        reloadPageAfterLocationChange: false,
      };
    default:
      return state;
  }
};

export default reducer;
