import Agent from "agentkeepalive";
import axios, { type AxiosRequestHeaders } from "axios";

import publicConfig from "config/public";

const keepAliveConfig: Agent.HttpOptions = {
  ...publicConfig.keepAliveSettings,
};

// Http agents are not isomorphic and only should be created and used on the server
export const httpAgent =
  typeof window === "undefined" ? new Agent(keepAliveConfig) : undefined;
export const httpsAgent =
  typeof window === "undefined"
    ? new Agent.HttpsAgent(keepAliveConfig)
    : undefined;

const serviceRequest = () => {
  const app = "web-web";
  const environment = publicConfig.serviceRequest.headers.environment;

  if (!app || !environment) {
    throw new Error("app and environment params are required");
  }

  const defaultHeaders: AxiosRequestHeaders = {
    "X-App": app,
    "X-Environment": environment,
  };

  if (typeof window === "undefined") {
    defaultHeaders["User-Agent"] = `${app} / ${environment}`;
  }

  const leaflyServiceRequest = axios.create({
    headers: {
      ...defaultHeaders,
    },
    httpAgent,
    httpsAgent,
  });

  leaflyServiceRequest.defaults.timeout = 10000;
  leaflyServiceRequest.interceptors.request.use((config) => {
    if (config.headers) {
      config.headers["Accept"] = "application/json";
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  });

  return leaflyServiceRequest;
};

export default serviceRequest;
