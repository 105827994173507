/*
 * Please try to keep GA event data clean. It is getting out of hand. See:
 * https://docs.google.com/document/d/1FNtKK4QUkgHUulxzJQC0cxI5OyO6xVwBbB9mdZgtJZg/edit?usp=sharing
 */

export enum Category {
  addToBag = "add to bag",
  bag = "bag",
  brandInfo = "brand info",
  brandPaid = "brand paid",
  brandResults = "brand results",
  button = "button",
  Carousel = "Carousel",
  carousel = "carousel",
  checkout = "checkout",
  checkoutConfirmation = "checkout confirmation page",
  checkoutPage = "checkout page",
  confirmInfo = "ConfirmInfo",
  dealCards = "deal cards",
  dealMenuPhenotypes = "deal_menu_phenotypes",
  dealMenuProductCategories = "deal_menu_product_categories",
  dealMenuStrains = "deal_menu_strains",
  dealsBogoCard = "deal cards - bogo",
  dealsBundleCard = "deal cards - bundle",
  dealsCartCard = "deal cards - cart",
  dealsCarouselCard = "deals_carousel_card",
  dealsMenuBrowseDeals = "deals menu browse deals",
  dealsMenuBrowsePromotions = "deals menu browse promotions",
  dealsMenuDealsCard = "Deals Menu Deals Card",
  dealsMenuLoadMoreDeals = "deals_menu_load_more_deals_button",
  dealsMenuPromotionsCard = "Deals Menu Promotions Card",
  dealsMenuSeeAllLocations = "see all locations link",
  dealsMenuSponsoredDealsCard = "deals menu sponsored deals card",
  dealsMerchandising = "deals_merchandising",
  dealsOnTopStrains = "deals on top strains",
  deliveryAddressModal = "Delivery Address Modal",
  deliveryGateFlow = "Delivery Gate Flow",
  descriptionLinks = "strain description links",
  dispensaryCard = "dispensary card",
  dispensaryFavorite = "dispensary favorite",
  dispensaryFooter = "dispensary footer",
  dispensaryHeader = "dispensary header",
  dispensaryHours = "dispensary hours",
  dispensaryInfo = "Dispensary Info",
  dispensaryMenu = "dispensary menu",
  dispensaryMenuPdp = "dispensary menu pdp",
  dispensaryMenuFilter = "Dispensary Menu Filter",
  dispensaryMenuProductTypeShortcut = "Dispensary Menu Product Type Shortcut",
  dispensaryMenuSort = "Dispensary Menu Sort",
  dispensaryMenuStrainTypeShortcut = "Dispensary Menu Strain Type Shortcut",
  dispensaryShop = "Shop dispensary",
  doctorProfile = "doctor profile",
  emptyBagState = "Empty Bag State",
  favorite = "favorite",
  featuredDealCards = "deal cards - featured",
  feedbackSurvey = "feedback survey",
  finder = "finder",
  finderFilter = "finder filter",
  finderSort = "finder sort",
  header = "header",
  headerNavigation = "header navigation",
  headerSearch = "header search",
  homepageFeature = "Homepage Feature",
  homepageSearch = "homepage_search",
  leafbot = "leafbot",
  loadMoreBrandResults = "load more brand results button",
  loadMoreDealsButton = "load more deals button",
  loadMorePromotionsButton = "load more promotions button",
  loadMoreResults = "load more results button",
  map = "map",
  mapSpotlight = "map spotlight",
  dispensaryStrainDetails = "dispensary strain details",
  menuDetails = "Dispensary Menu Details View",
  menuItemCard = "menu item card",
  menuItemCardTray = "menu item card tray",
  menuItemDetailVariants = "menu item detail variants",
  newsArticleCard = "news article card",
  notification = "Notification",
  outboundLink = "outbound link",
  orderAgainCarousel = "Order Again Carousel",
  orderHistory = "Order History",
  productCategoryCoinFilter = "product category coin filter",
  productFilterBar = "product_filter_bar",
  productPage = "Products",
  productSubcategoryPage = "Product Subcategory Page",
  recentlyViewedMenuItems = "recently viewed menu items",
  resultsSorted = "results sorted",
  retailerCard = "retailer card",
  reviews = "Reviews",
  reservationStatus = "Reservation Status",
  schedulingModal = "scheduling modal",
  searchResults = "search_results",
  searchShopResults = "search_shop_results",
  shopAllMenus = "shop all menus button",
  strainsFilter = "Strains Filter",
  strainsSort = "Strains Sort",
  strainDetail = "strain detail",
  strainDetailsPage = "Strain Details Page",
  strainType = "strain type",
  showAllLocationResults = "show all location results button",
  showMoreNewsResults = "show more news results button",
  showMoreStrainResultsButton = "show_more_strain_results_button",
  sitewideBanner = "sitewide banner",
  strainCard = "strain_card",
  strainDescriptionLink = "strain description links",
  strainReview = "Strain Review Page",
  strainTypeFilter = "strain type filter",
  viewDispensaryMap = "view dispensary map button",
  webProfile = "Web Profile",
  writeStrainReview = "Write Strain Review",
  homepagePaidAd = "Paid Homepage Marquee",
  homepageHouseAd = "House Homepage Marquee",
  strainListPaidAd = "Paid Strain List Marquee",
  pagination = "pagination",
  strainListHouseAd = "House Strain List Marquee",
  dealsPageListPaidAd = "Paid Deals Page Marquee",
  dealsPageListHouseAd = "House Deals Page Marquee",
  productFirstShoppingFlow = "Product First Shopping Flow",
}

export enum Action {
  affiliateLinkClick = "BUY HERE",
  categorySelected = "category_selected",
  click = "click",
  Click = "Click",
  contactClicked = "Contact clicked",
  dealsMerchandisingClicked = "deals_merchandising_clicked",
  dealsMerchandisingImpression = "deals_merchandising_impression",
  directionsClicked = "Directions clicked",
  emailAdded = "Email added",
  impression = "impression",
  nameAdded = "Name added",
  notificationClick = "notification click",
  phoneAdded = "Phone added",
  searchResultClicked = "search_result_clicked",
  searchResultsFiltered = "search_results_filtered",
  searchResultsSorted = "search_shop_results_sorted",
  searchShopNullResults = "search_shop_null_results",
  submit = "Submit",
}

export enum Label {
  strainNameLink = "strain name link",
  UberEgress = "uber egress",
  page = "page",
  nextArrow = "next arrow",
  previousArrow = "previous arrow",
  leaflyListAwards = "leafly list awards",
  learnMoreAboutStrainLink = "learn more about strain link",
  addToBag = "add to bag",
  strainReview = "strain reviews",
  featuredArticle = "featured article",
  changeDateAndTime = "change date and time",
  viewStrainDetailsModule = "view strain details module",
  confirmation = "confirmation",
  confirmOrder = "confirm order",
  closeSchedule = "close schedule",
  dispensaryReservations = "Dispensary Reservations",
  menuItemCardViewDetails = "menu item card - view details",
  menuItemCardViewDetailsButton = "menu item card - view details button",
  favoriteDispensaryStrainDetails = "favorite dispensary strain details",
  unfavoriteDispensaryStrainDetails = "unfavorite dispensary strain details",
  unfollowButton = "unfollow button",
  followButton = "follow button",
  nullResults = "null_results",
  selectedOption = "selected_option=",
  scheduleEnabled = "schedule enabled",
  modal = "modal",
  showMoreStrainResultsButton = "show more strain results button",
  submitAppointment = "Submit Appointment",
  variantConfirmation = "variant confirmation",
  variantSelection = "variant selection",
  viewBag = "view bag",
  writeReview = "write review",
  appDownloadBanner = "app download banner",
  appDownloadOpen = "app download banner - open",
  appDownloadClose = "app download banner - close",
  requestOnlineOrdering = "request online ordering",
  claimYourBrand = "claim your brand",
  inStoreGetDirections = "in-store get directions",
  promoCreateAccountSignIn = "promo create account sign in",
}
