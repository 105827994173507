import { useRouter } from "next/router";
import { stringify } from "qs";

import { Query } from "custom-types/Query";
import {
  generateBaseUrl,
  generateSignInUrl,
  generateSignOutUrl,
  generateSignUpUrl,
} from "utils/generateSsoUrls";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

import useDomainCountryCode from "./useDomainCountryCode";

type Prop = {
  redirectUrlParams?: Query;
  redirectPath?: string;
  ssoUrlParams?: Query;
};

type Props = {
  base?: Prop;
  signIn?: Prop;
  signOut?: Prop;
  signUp?: Prop;
};

const generateRedirectPath = (asPath: string, params: Query) => {
  const { path, query } = parseNextRouterAsPath(asPath);
  const queryString = stringify({ ...query, ...params });

  return `${path}?${queryString}`;
};

/*
 * This hook is used to generate SSO URLs for the current domain country code.
 * It returns the base URL, sign in URL, sign out URL, and sign up URL.
 *
 * For each URL, you can assign the following props:
 * - redirectUrlParams: which combines the passed object with the current
 *  query params and appends them to the current path
 * - redirectPath: which overrides the current path
 * - ssoUrlParams: which appends the passed object as a query string to the SSO URL itself
 */
export const useSsoUrls = (props?: Props) => {
  const { asPath } = useRouter();

  const domainCountryCode = useDomainCountryCode();

  return {
    baseUrl: generateBaseUrl(
      domainCountryCode,
      props?.base?.redirectUrlParams
        ? generateRedirectPath(
            props.base.redirectPath || asPath,
            props.base.redirectUrlParams,
          )
        : props?.base?.redirectPath || asPath,
      props?.base?.ssoUrlParams,
    ),
    signInUrl: generateSignInUrl(
      domainCountryCode,
      props?.signIn?.redirectUrlParams
        ? generateRedirectPath(
            props.signIn.redirectPath || asPath,
            props.signIn.redirectUrlParams,
          )
        : props?.signIn?.redirectPath || asPath,
      props?.signIn?.ssoUrlParams,
    ),
    signOutUrl: generateSignOutUrl(
      domainCountryCode,
      props?.signOut?.redirectUrlParams
        ? generateRedirectPath(
            props.signOut.redirectPath || asPath,
            props.signOut.redirectUrlParams,
          )
        : props?.signOut?.redirectPath || asPath,
      props?.signOut?.ssoUrlParams,
    ),
    signUpUrl: generateSignUpUrl(
      domainCountryCode,
      props?.signUp?.redirectUrlParams
        ? generateRedirectPath(
            props?.signUp?.redirectPath || asPath,
            props?.signUp.redirectUrlParams,
          )
        : props?.signUp?.redirectPath || asPath,
      props?.signUp?.ssoUrlParams,
    ),
  };
};
