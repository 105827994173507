import React, { HTMLAttributes } from "react";

import HamburgerMenuIcon from "components/Icons/hamburger_menu.svg";

export const HamburgerButton = React.forwardRef<
  HTMLButtonElement,
  HTMLAttributes<HTMLButtonElement>
>(({ children, onClick }, ref) => {
  return (
    <div className="flex flex-shrink-0 relative mr-lg header__menu">
      <button
        data-testid="Menu"
        aria-label="Open site navigation"
        onClick={onClick}
        ref={ref}
      >
        <HamburgerMenuIcon height="24" width="24" />
        {children}
      </button>
    </div>
  );
});
