import { IncomingHttpHeaders } from "http";

import {
  SET_DOMAIN_LOCATION,
  SetDomainCountryCodeAction,
} from "redux/reducers/config";
import { getCountryCodeHeader } from "utils/requestContext";

export const setDomainCountryCode = (
  headers: IncomingHttpHeaders,
): SetDomainCountryCodeAction => ({
  countryCode: getCountryCodeHeader(headers),
  type: SET_DOMAIN_LOCATION,
});
