export type EducationalMaterialsStoreFrontImage = {
  altText: string;
  linkUrl: string;
  sourceUrls: {
    desktop: string;
    mobile: string;
  };
};

export type EducationalMaterials = {
  storeFront: null | {
    images: EducationalMaterialsStoreFrontImage[];
  };
  licenseVerificationLink: null | {
    url: string;
    text: string;
  };
};

export type ComplianceRuleset = {
  id?: string;
  subregionCode?: string;
  regionCode?: string;
  rules?: Record<string, string | number | boolean | null> & {
    educationalMaterials?: EducationalMaterials;
  };
  created?: string;
  lastModified?: string;
};

export type ComplianceRulesState = {
  complianceRuleset: ComplianceRuleset;
};

export const initialState: ComplianceRulesState = {
  complianceRuleset: {
    regionCode: "Default",
    rules: {
      medicalDeliveryAllowed: true,
      pickupAllowed: true,
      recreationalDeliveryAllowed: true,
    },
  },
};

export const COMPLIANCE_RULESET_LOAD = "COMPLIANCE_RULES_LOADED";

export type ComplianceRulesLoadedAction = {
  type: typeof COMPLIANCE_RULESET_LOAD;
  complianceRuleset: ComplianceRuleset;
};

export type ComplianceRulesActions = ComplianceRulesLoadedAction;

export const complianceRulesReducer = (
  state = initialState,
  action: ComplianceRulesActions,
): ComplianceRulesState => {
  switch (action.type) {
    case COMPLIANCE_RULESET_LOAD:
      return {
        ...state,
        complianceRuleset: action.complianceRuleset,
      };
    default:
      return state;
  }
};

export default complianceRulesReducer;
