import React, { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

import getSearchSuggestions from "api/requests/consumerApi/getSearchSuggestions";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import {
  CLOSE_SEARCH,
  OPEN_SEARCH,
  SUBMIT_SEARCH,
  UPDATE_SEARCH_SUGGESTIONS,
} from "redux/reducers/header";
import { getCoordinates } from "redux/selectors/location";
import { availableStorage } from "utils/storage";

import SearchIcon from "components/Icons/search.svg";

const SearchOverlaySlim = dynamic(
  () => import("components/Header/SearchOverlaySlim"),
);

export const SearchBarSlim: React.FC = () => {
  const countryCode = useDomainCountryCode();
  const location = useSelector(getCoordinates);
  const dispatch = useDispatch();
  const router = useRouter();
  const [searchIsOpen, setSearchIsOpen] = useState<boolean | false>();
  const [searchText, setSearchText] = useState<string | null>();
  const { publishEvent } = useEventTracker();

  const openSearch = () => {
    if (searchIsOpen) {
      return closeSearch();
    }
    publishEvent({
      action: "click",
      category: "header search",
      label: "open",
    });

    setSearchIsOpen(true);
    dispatch({
      searchText,
      storage: availableStorage(),
      type: OPEN_SEARCH,
    });
  };

  const closeSearch = () => {
    publishEvent({
      action: "click",
      category: "header search",
      label: "close",
    });
    setSearchText(null);
    setSearchIsOpen(false);
    dispatch({
      searchText,
      storage: availableStorage(),
      type: CLOSE_SEARCH,
    });
  };

  const onSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    publishEvent({
      action: "click",
      category: "header search",
      label: "search executed",
    });
    dispatch({
      searchText,
      storage: availableStorage(),
      type: SUBMIT_SEARCH,
    });
    setSearchIsOpen(false);
    e.preventDefault();
    router.push(`/search?q=${searchText}`);
  };

  const retrieveSearchSuggestions = () => {
    if (searchText && searchText.length > 2) {
      getSearchSuggestions(location, countryCode, searchText).then(
        (suggestions) => {
          dispatch({
            suggestions: suggestions?.map((s) => ({
              distanceMi: s.distanceMi,
              iconFilePath: "search.svg",
              id: s.id,
              link: s.link,
              subtitle: s.type,
              title: s.title,
            })),
            type: UPDATE_SEARCH_SUGGESTIONS,
          });
        },
      );

      if (!searchIsOpen) {
        // User can hit escape, then type new text in the input - which should open the beard up again.
        dispatch({
          searchText,
          storage: availableStorage(),
          type: OPEN_SEARCH,
        });
      }
    } else {
      // This is for when you delete your text and should see the recent search history again
      dispatch({
        suggestions: [],
        type: "header/updateSearchSuggestions",
      });
    }
  };

  const onSearch = debounce(retrieveSearchSuggestions);

  useEffect(() => {
    onSearch();
  }, [searchText]);

  return (
    <div data-testid="global-slim-search-container" className="lg:hidden">
      <button
        className="flex items-center justify-center mr-xl"
        aria-label="Search Leafly"
        onClick={openSearch}
        type="button"
      >
        <SearchIcon
          className="flex-shrink-0 block search__icon"
          height="24"
          width="24"
        />
      </button>
      {searchIsOpen && (
        <SearchOverlaySlim
          onSearchSubmit={onSearchSubmit}
          setSearchText={setSearchText}
          closeSearch={closeSearch}
        />
      )}
    </div>
  );
};
