import React, { HTMLAttributes } from "react";

import { Indicator } from "./Indicator";

export const NotificationIndicator: React.FC<
  {
    unreadCount: number | null | undefined;
  } & HTMLAttributes<HTMLDivElement>
> = ({ unreadCount, ...props }) => {
  if (
    unreadCount === null ||
    typeof unreadCount === "undefined" ||
    Number(unreadCount) === 0
  ) {
    return null;
  }

  return (
    <Indicator {...props}>
      {Number(unreadCount) > 9 ? "9+" : unreadCount}
    </Indicator>
  );
};
