import React from "react";

import useDomainCountryCode from "hooks/useDomainCountryCode";

import { ResponsiveAccordion } from "./ResponsiveAccordion";
import { useFooterClickEventTracker } from "./useFooterClickEventTracker";

export const BusinessSolutions: React.FC<{ isResponsive?: boolean }> = ({
  isResponsive,
}) => {
  const isCanada = useDomainCountryCode() === "CA";
  const { createPublishFooterClickEventFunction } =
    useFooterClickEventTracker();

  return (
    <ResponsiveAccordion
      label="Business Solutions"
      defaultExpanded={true}
      isResponsive={isResponsive}
    >
      <ul className="text-sm text-green font-bold">
        {isCanada ? (
          <>
            <li className="py-sm">
              <a
                href="https://success.leafly.ca?utm_source=dispensary_link&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=SOL"
                onClick={createPublishFooterClickEventFunction(
                  "list your store",
                )}
              >
                List your store
              </a>
            </li>
            <li className="py-sm">
              <a
                href="https://success.leafly.ca?utm_source=brands_link&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=SOL"
                onClick={createPublishFooterClickEventFunction(
                  "list your brand",
                )}
              >
                List your brand
              </a>
            </li>
            <li className="py-sm">
              <a
                href="https://success.leafly.ca/labs"
                onClick={createPublishFooterClickEventFunction(
                  "certify your lab",
                )}
              >
                Lab partners
              </a>
            </li>
          </>
        ) : (
          <>
            <li className="py-sm">
              <a
                href="https://success.leafly.com/retail?utm_source=site_footer&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=list_your_store"
                onClick={createPublishFooterClickEventFunction(
                  "list your store",
                )}
              >
                List your store
              </a>
            </li>
            <li className="py-sm">
              <a
                href="https://success.leafly.com/cbd?utm_source=site_footer&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=list_your_cbd_store"
                onClick={createPublishFooterClickEventFunction(
                  "list your cbd store",
                )}
              >
                List your CBD store
              </a>
            </li>
            <li className="py-sm">
              <a
                href="https://success.leafly.com/brands?utm_source=site_footer&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=list_your_brand"
                onClick={createPublishFooterClickEventFunction(
                  "list your brand",
                )}
              >
                List your brand
              </a>
            </li>
            <li className="py-sm">
              <a
                href="https://success.leafly.com/doctors?utm_source=site_footer&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=list_your_practice"
                onClick={createPublishFooterClickEventFunction(
                  "list your practice",
                )}
              >
                List your practice
              </a>
            </li>
            <li className="py-sm">
              <a
                href="https://success.leafly.com/labs?utm_source=site_footer&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=certify_your_lab"
                onClick={createPublishFooterClickEventFunction(
                  "certify your lab",
                )}
              >
                Certify your lab
              </a>
            </li>
            <li className="py-sm">
              <a
                href="https://business.leafly.com/"
                onClick={createPublishFooterClickEventFunction(
                  "business log in",
                )}
              >
                Business log in
              </a>
            </li>
          </>
        )}
      </ul>
    </ResponsiveAccordion>
  );
};
