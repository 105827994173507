import { Coordinates } from "./Coordinates";
import { WeeklySchedule } from "./DispensarySchedules";
import { LocationPaths } from "./LocationPaths";

export type DispensaryDaySchedule = {
  open: string;
  close: string;
  isOpen: boolean;
};

export type DispensaryPhoto = {
  id: number;
  bigThumb?: string;
  created?: string | Date;
  imageUrl: string;
  lastModified?: string | Date;
  smallThumb?: string;
  user?: string;
};

export type DispensaryServiceAreaRanges = {
  minFee: number;
  freeDeliveryMinimum: number | null;
  maxFee: number;
  minOrderMinimum: number;
  maxOrderMinimum: number;
  minDeliveryDurationMinimum: number;
  maxDeliveryDurationMaximum: number;
  minFreeDeliveryMinimum: number | null;
  maxFreeDeliveryMinimum: number | null;
};

export type DeliveryServiceAreaInfo = {
  fee: number;
  orderMinimum: number;
  deliveryDurationMinimum: number;
  deliveryDurationMaximum: number;
};

type ChangeTime = {
  day:
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday"
    | "monday";
  time: string;
  type: "open" | "close";
};

export type CurrentStatus = {
  statusChangeAt: ChangeTime;
  isOpen: boolean;
};

export type ScheduleStatuses = {
  delivery: CurrentStatus | null;
  store: CurrentStatus | null;
  pickup: CurrentStatus | null;
};

export type Dispensary = {
  acceptsCash: boolean;
  acceptsCreditCards: boolean;
  acceptsDebitCards: boolean;
  address1: string;
  address2: string;
  atmosphereRating: number;
  city: string;
  clinicWalkInsWelcome: boolean;
  country: string;
  coverPhotoUrl: string;
  coverImage?: string;
  coverImages?: CoverImages;
  currentStatuses: ScheduleStatuses | null;
  created: string;
  deliveryEnabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  deliveryFee: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  deliveryOrderMinimum: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  deliveryPaymentMethods: any;
  deliveryRadiusMiles: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  deliveryRadiusPostalCodeBlacklist: any;
  deliveryServiceAreaRanges?: DispensaryServiceAreaRanges;
  deliveryServiceAreaInfo?: DeliveryServiceAreaInfo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  deliveryTimeEstimate?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  deliveryZonePostalCodeWhitelist: any;
  deliveryZoneRangeType: string;
  description: string;
  distanceMi?: number;
  email: string;
  featureTier: string;
  flags?: string[];
  followerCount: number;
  hasAgreedToPrivacyTos: boolean;
  hasAtm: boolean;
  hasDeliveryEnabled: boolean;
  hasJaneCheckoutEnabled: boolean;
  hasMenuItems: boolean;
  hasReservationsEnabled: boolean;
  hasVeteranDiscount: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  hours?: any;
  id: number;
  inStoreCartEnabled?: boolean;
  inStorePaymentsAccepted: boolean;
  isAd?: boolean;
  isAdaAccessible: boolean;
  isApproved: boolean;
  isPreorderOpen: boolean;
  isPickupOpen: boolean;
  isDeliveryOpen: boolean;
  isNewDispensary: boolean;
  isUfcwMember: boolean;
  lastMenuUpdate: string;
  leaflyListYears: number[];
  licenseNumber: string;
  location?: Coordinates;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  locations: any;
  locationPaths?: LocationPaths;
  logoUrl: string;
  logoImage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  mapMarkerLocations: any;
  mapMarkerLevel?: string;
  menuSyncOptionId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  merchandisedCampaigns?: Array<any>;
  name: string;
  numberOfReviews?: number;
  offersMedicalCards: boolean;
  offersWalletSizedMedicalCards: boolean;
  onlineFulfillmentEnabled: boolean;
  onlinePaymentsAccepted: boolean;
  organizationSlug: string;
  phone: string;
  photos: Array<DispensaryPhoto>;
  pickupEnabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  pickupPaymentMethods: any;
  pickupMinutesMaximum?: number;
  pickupMinutesMinimum?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  pickupTimeEstimate: any;
  preferredPickupPartner: boolean;
  premiumRank?: PremiumRank;
  preorderConfigured: boolean;
  preorderEnabled: boolean;
  primaryLocation: Coordinates;
  productCategories?: {
    name: string;
  }[];
  qualityRating: number;
  rating: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  recentUpdates?: Array<any>;
  retailType: string;
  reviewCount: number;
  roundedRating: number;
  schedules: WeeklySchedule;
  scheduledDeliveryEnabled: boolean;
  slug: string;
  serviceRating: number;
  specialCheckoutInstructions: string | null;
  sponsoredVideoId?: string;
  starRating?: number;
  state: string;
  supportsSmsNotifications: boolean;
  tagLine: string;
  tags: DispensaryTagsEnum[];
  tier?: 0 | 1 | 2 | 3 | 4 | 200 | 300 | 400 | 900;
  timeZone: string;
  topLevelOrganizationSlug?: string;
  verifiesMedicalCards247: boolean;
  website: string;
  zip: string;
  openNow: boolean;
  uberEatsStoreUrl?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  openNext?: any;
  openUntil?: string | Date;
  latitude?: string;
  longitude?: string;
  matchedStrainNames?: {
    name: string;
  }[];
};

export type DeliveryServiceArea = {
  freeDeliveryMinimum: number | null;
  deliveryDurationMaximum: string | number;
  deliveryDurationMinimum: string | number;
  fee: string | number;
  id: number;
  name: string;
  orderMinimum: string | number;
};

type DeliveryViolation = {
  error: string;
  message: string;
};

type PremiumRank = "diamond" | "platinum" | "gold" | "silver" | "bronze";

export type DeliveryServiceAreaResponse = {
  result: boolean;
  deliveryServiceArea?: DeliveryServiceArea;
  violations?: Array<DeliveryViolation>;
};

export type CoverImages = {
  small: string;
  medium: string;
  large: string;
};

// for finder v3 endpoint
export type DispensariesResponse = {
  stores: Dispensary[];
  sponsoredStores: Dispensary[];
  spotlight: Dispensary[];
  storesCount: number;
  currentPage: number;
  pageCount: number;
  // unsure of what this might look like based on swagger and api response
  availableFilters: {
    param: string;
    display: string;
  }[];
  availableSorts: {
    param: string;
    display: string;
  }[];
};

// These types used to be imported from API-OG but were extracted
// due to bundle sizing issues. Please consult API-OG code if you
// feel something may be missing.
export enum DispensaryTagsEnum {
  Medical = "medical",
  Recreational = "recreational",
  Delivery = "delivery",
  CanadaLp = "canadaLp",
  Storefront = "storefront",
  AzLicensed = "azLicensed",
  ComingSoon = "comingSoon",
  Licensed = "licensed",
  DualLicense = "dualLicense",
  Indigenous = "indigenous",
}
export enum RetailType {
  Clinic = "clinic",
  Dispensary = "dispensary",
  CbdStore = "cbd-store",
}
