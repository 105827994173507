import { AllActions } from "./rootReducer";

export type MenuItemState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  currentMenuItem: any | null;
  loaded: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  similarMenuItems: Array<any>;
  similarItemsLoaded: boolean;
};

const initialState: MenuItemState = {
  currentMenuItem: null,
  loaded: false,
  similarItemsLoaded: false,
  similarMenuItems: [],
};

export const MENU_ITEM_LOAD = "menuItem/load";
export const MENU_ITEM_SIMILAR_ITEMS_LOAD = "menuItem/similarItems/load";

export type MenuItemLoadAction = {
  type: typeof MENU_ITEM_LOAD;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  menuItem: any;
};

export type MenuItemSimilarItemsAction = {
  type: typeof MENU_ITEM_SIMILAR_ITEMS_LOAD;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  menuItems: Array<any>;
};

export type MenuItemActions = MenuItemLoadAction | MenuItemSimilarItemsAction;

const menuItemReducer = (
  state = initialState,
  action: AllActions,
): MenuItemState => {
  switch (action.type) {
    case "menuItem/load":
      return {
        ...state,
        currentMenuItem: action.menuItem,
        loaded: true,
      };
    case "menuItem/similarItems/load":
      return {
        ...state,
        similarItemsLoaded: true,
        similarMenuItems: action.menuItems || [],
      };
    default:
      return state;
  }
};

export default menuItemReducer;
