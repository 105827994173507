import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";

import { CLOSE_NAVIGATION } from "redux/reducers/header";

const NavigationItem: React.FC<{
  as?: string;
  className?: string;
  highlight?: Array<string | RegExp>;
  href: string;
  onClick?: () => void;
  children?: React.ReactNode;
}> = ({ as, className, children, highlight, href, onClick }) => {
  const dispatch = useDispatch();
  const { asPath } = useRouter();
  const matchesHighlights = (highlight || []).some((pattern) =>
    typeof pattern === "string"
      ? asPath.startsWith(pattern)
      : asPath.match(pattern),
  );
  const matchesHref = asPath === href;

  const AsElement = (as || Link) as React.ElementType;

  return (
    <li>
      <AsElement
        data-testid="navigation-item"
        className={classNames(className, {
          "font-bold": matchesHref || matchesHighlights,
        })}
        href={href}
        onClick={() => {
          onClick?.();
          dispatch({ type: CLOSE_NAVIGATION });
        }}
      >
        {children}
      </AsElement>
    </li>
  );
};

export default NavigationItem;
