import { MenuItemVariant } from "custom-types/MenuItem";

export enum MenuType {
  Med = "med",
  Rec = "rec",
}

export const MENU_TITLES: {
  [key: string]: { [key: string]: string };
} = {
  dual: {
    shortTitle: "Med & Rec",
  },
  indigenous: {
    caTitle: "First Nations",
    title: "Indigenous",
  },
  med: {
    longLower: "medical",
    longTitle: "Medical",
    shortLower: "med",
    shortTitle: "Med",
  },
  rec: {
    longLower: "recreational",
    longTitle: "Recreational",
    shortLower: "rec",
    shortTitle: "Rec",
  },
};

export const menuTypeCookieName = "userMedRecPreference";
export const menuTypeErrorMessage = "multiple_menu_type_error";
export const defaultMenuType = MENU_TITLES.med.shortLower as MenuType;

export const validatedMenuType = (value: string): string | null => {
  return (value && MENU_TITLES[value.toLowerCase()]?.shortTitle) || null;
};

export const includesDualMenuTag = (tags: string[]): boolean =>
  tags?.includes("dualLicense");

export const getMenuTypeLabel = ({
  tags = [],
  isCA,
}: {
  tags?: string[];
  isCA?: boolean;
} = {}) => {
  if (tags.includes("indigenous")) {
    return isCA
      ? MENU_TITLES.indigenous.caTitle.toUpperCase()
      : MENU_TITLES.indigenous.title.toUpperCase();
  }
  if (
    includesDualMenuTag(tags) ||
    (tags.includes("medical") && tags.includes("recreational"))
  ) {
    return MENU_TITLES.dual.shortTitle.toUpperCase();
  }
  if (tags?.includes("medical")) {
    return MENU_TITLES.med.shortTitle.toUpperCase();
  }
  if (tags?.includes("recreational")) {
    return MENU_TITLES.rec.shortTitle.toUpperCase();
  }

  return null;
};

export const getShortTitle = (value: MenuType) => MENU_TITLES[value].shortTitle;

export const getLongTitle = (type: MenuType): string =>
  MENU_TITLES[type].longTitle;

// Tool for getting the med/rec preference cookie using a req object
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export const getMenuTypeCookie = (req: any) => {
  const userCookiePreference = req?.cookies[menuTypeCookieName];
  return validatedMenuType(userCookiePreference) || defaultMenuType;
};

export const testVariantsForMenuType =
  (desiredMenuType: MenuType) =>
  (variant: MenuItemVariant): boolean =>
    variant.medical === (desiredMenuType === MenuType.Med);
