export enum ToastType {
  Info,
  Warning,
  Error,
}

export type Toast = {
  id: string;
  message: string | JSX.Element;
  type: ToastType;
};

type ToastsState = {
  toasts: Toast[];
};

export const initialState: ToastsState = {
  toasts: [],
};

export const TOASTS_NOTIFY = "toasts/notify";
export const TOASTS_DISMISS = "toasts/dismiss";

type ToastReducerActionNotify = {
  type: typeof TOASTS_NOTIFY;
  value: Toast;
};

type ToastReducerActionDismiss = {
  type: typeof TOASTS_DISMISS;
  value: string;
};

export type ToastsActions =
  | ToastReducerActionNotify
  | ToastReducerActionDismiss;

const toastReducer = (
  state = initialState,
  action: ToastsActions,
): ToastsState => {
  switch (action.type) {
    case TOASTS_NOTIFY: {
      return { ...state, toasts: [...state.toasts, action.value] };
    }
    case TOASTS_DISMISS: {
      return {
        ...state,
        toasts: state.toasts.filter((item) => item.id !== action.value),
      };
    }
    default:
      return state;
  }
};

export default toastReducer;
