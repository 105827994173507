"use client";

import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";

import useCoreWebVitals from "hooks/useCoreWebVitals";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useGoogleAnalyticsUserLocation } from "hooks/useGoogleAnalyticsUserLocation";
import { useIFrameAccessibilityFix } from "hooks/useIFrameAccessibilityFix";
import { createLoadCookiesAction } from "redux/action-creators/cookies";
import { loadUser } from "redux/action-creators/user";
import {
  CREATE_CART_SESSION_ID,
  CreateCartSessionIdAction,
} from "redux/reducers/cart";
import { DISABLE_LOADING_SPINNER } from "redux/reducers/header";
import { USER_LOGGED_OUT_REDIRECT } from "redux/reducers/user";
import { getIsLoggedIn } from "redux/selectors/user";

/**
 * This component encapsulates side-effects that the app requires in a leaf
 * node, in order to prevent unnecessarily re-rendering any of the application
 * when the dependent state changes. If this code was in the App component
 * itself, changes to the redux state would trigger a re-render of anything
 * rendered by App.
 */
export const AppSideEffects = () => {
  const countryCode = useDomainCountryCode();
  const dispatch = useDispatch();
  const path = usePathname();
  const query = useSearchParams();
  const isLoggedIn = useSelector(getIsLoggedIn);

  useCoreWebVitals();
  useIFrameAccessibilityFix();
  useGoogleAnalyticsUserLocation();

  useEffect(() => {
    dispatch(createLoadCookiesAction());
    dispatch<CreateCartSessionIdAction>({
      countryCode,
      type: CREATE_CART_SESSION_ID,
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    loadUser(countryCode, dispatch);
  }, [isLoggedIn]);

  useEffect(() => {
    if (query?.has("sign_out_redirect")) {
      dispatch({ isLoggedOutRedirect: true, type: USER_LOGGED_OUT_REDIRECT });
    }
    dispatch({
      type: DISABLE_LOADING_SPINNER,
    });
  }, [path]);

  return null;
};
