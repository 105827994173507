import React from "react";
import Link from "next/link";

import { ResponsiveAccordion } from "./ResponsiveAccordion";
import { useFooterClickEventTracker } from "./useFooterClickEventTracker";

export const PrivacyAndTerms: React.FC<{ isResponsive?: boolean }> = ({
  isResponsive,
}) => {
  const { createPublishFooterClickEventFunction } =
    useFooterClickEventTracker();

  return (
    <ResponsiveAccordion
      label="Privacy &amp; Terms"
      defaultExpanded={false}
      isResponsive={isResponsive}
    >
      <ul className="text-sm text-green font-bold">
        <li className="py-sm mr-md">
          <Link
            href={`/info/terms-of-use`}
            onClick={createPublishFooterClickEventFunction("terms of use")}
          >
            Terms of use
          </Link>
        </li>
        <li className="py-sm mr-md">
          <Link
            href={`/info/commercial-terms-of-use`}
            onClick={createPublishFooterClickEventFunction(
              "commercial terms of use",
            )}
          >
            Commercial terms of use
          </Link>
        </li>
        <li className="py-sm mr-md">
          <Link
            href={`/info/privacy-policy`}
            onClick={createPublishFooterClickEventFunction("privacy policy")}
          >
            Privacy policy
          </Link>
        </li>
        <li className="py-sm mr-md">
          <Link
            href={`/info/privacy-policy#ccpa-do-not-sell`}
            onClick={createPublishFooterClickEventFunction(
              "do not sell my personal information",
            )}
          >
            Do not sell my personal information
          </Link>
        </li>
      </ul>
    </ResponsiveAccordion>
  );
};
