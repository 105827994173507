import { useEffect, useRef } from "react";
import classNames from "classnames";
import throttle from "lodash/throttle";
import { useDispatch, useSelector } from "react-redux";

import getUserNotificationCounts from "api/requests/getUserNotificationCounts";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEvent } from "hooks/useEvent";
import { useEventTracker } from "hooks/useEventTracker";
import {
  COLLAPSE_HEADER,
  EXPAND_HEADER,
  OPEN_NAVIGATION,
  UPDATE_NOTIFICATION_COUNT,
} from "redux/reducers/header";
import {
  getHeaderCollapsed,
  getNotificationCount,
} from "redux/selectors/header";
import { getIsLoggedIn } from "redux/selectors/user";

import { AdvertisingLink } from "./AdvertisingLink";
import { Banner } from "./Banner";
import Cart from "./Cart";
import { HamburgerButton } from "./HamburgerButton";
import { HeaderLinks } from "./HeaderLinks";
import { LocationIndicator } from "./LocationIndicator";
import { LocationPickerModal } from "./LocationPickerModal";
import { Logo } from "./Logo";
import { NotificationIndicator } from "./NotificationIndicator";
import { SearchBar } from "./SearchBar";
import { SearchBarSlim } from "./SearchBarSlim";
import SidebarNavigation from "./SidebarNavigation/SidebarNavigation";

const HEADER_HEIGHT = 90;

export const Header = ({
  disableFixedPosition = false,
  disableScrollCollapse = false,
  isAppBannerCookieDismissed = false,
  minimal = false,
}: {
  disableFixedPosition?: boolean;
  disableScrollCollapse?: boolean;
  isAppBannerCookieDismissed?: boolean;
  minimal?: boolean;
}) => {
  const unreadCount = useSelector(getNotificationCount);
  const countryCode = useDomainCountryCode();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const collapsed = useSelector(getHeaderCollapsed);
  const hamburgerRef = useRef<HTMLButtonElement>(null);
  const locationIndicator = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const { publishEvent } = useEventTracker();

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    getUserNotificationCounts({ countryCode }).then((counts) => {
      dispatch({
        count: counts?.unread,
        type: UPDATE_NOTIFICATION_COUNT,
      });
    });
  }, [isLoggedIn, countryCode]);

  const prevScrollY = useRef(0);
  const dispatchCollapse = () => {
    const scrollPosition = window.scrollY;
    if (
      collapsed &&
      (scrollPosition < HEADER_HEIGHT || scrollPosition < prevScrollY.current)
    ) {
      dispatch({ type: EXPAND_HEADER });
    } else if (
      !collapsed &&
      scrollPosition > prevScrollY.current &&
      scrollPosition > HEADER_HEIGHT
    ) {
      dispatch({ type: COLLAPSE_HEADER });
    }

    prevScrollY.current = scrollPosition;
  };

  const onScroll = throttle(useEvent(dispatchCollapse), 250, {
    leading: true,
  });

  useEffect(() => {
    if (disableScrollCollapse || minimal || disableFixedPosition) {
      return;
    }

    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [disableScrollCollapse, minimal, disableFixedPosition]);

  return (
    <>
      <div
        className={classNames("z-header w-full bg-white text-green top-0", {
          collapsed: collapsed || minimal,
          "fixed transition-transform motion-reduce:transition-none":
            !disableFixedPosition,
          relative: disableFixedPosition,
        })}
        data-testid="header"
        id="header-container"
      >
        <Banner
          disableFixedPosition={disableFixedPosition}
          isAppBannerCookieDismissed={isAppBannerCookieDismissed}
          minimal={minimal}
        />

        <header className="shadow-low relative">
          <div className="container pb-md lg:py-lg pt-md">
            <div className="flex flex-wrap justify-between items-center">
              <div className="flex">
                <HamburgerButton
                  ref={hamburgerRef}
                  onClick={() => {
                    publishEvent({
                      action: "click",
                      category: "header navigation",
                      label: "hamburger - open",
                    });
                    dispatch({ type: OPEN_NAVIGATION });
                  }}
                >
                  <NotificationIndicator
                    unreadCount={unreadCount}
                    data-testid="notification-count"
                  />
                </HamburgerButton>
                <Logo />
                <AdvertisingLink />
              </div>
              <div className="hidden lg:block flex-1">
                {!minimal && <SearchBar />}
              </div>
              <div className="flex items-center justify-center">
                {!minimal && <SearchBarSlim />}
                {!minimal && <LocationIndicator ref={locationIndicator} />}
                <Cart />
              </div>
            </div>
            {!minimal && <HeaderLinks />}
          </div>
        </header>
      </div>

      <LocationPickerModal returnFocusRef={locationIndicator} />
      <SidebarNavigation returnFocusRef={hamburgerRef} />

      {/*
          The transitions defined in the CSS below are not just for fun! The metrics
          that Google collects on Cumulative Layout Shift counts the change in the
          header when scrolling up and down as Layout Shift, even though it is
          technically triggered by user interaction; the scroll event is not included
          in the list of user events that can trigger document reflow.

          See further documentation here: https://wicg.github.io/layout-instability/#sec-input-exclusion
        */}
      <style jsx global>{`
        :root {
          --header-height: ${minimal ? 48 : 52}px;
        }

        @media (min-width: 1025px) {
          :root {
            --header-height: ${minimal ? 56 : 106}px;
          }
        }

        body {
          padding-top: calc(var(--app-banner-height) + var(--header-height));
        }

        @media (max-width: 1024px) {
          #header-container.collapsed #global-search,
          #header-container.collapsed #global-header-nav-links {
            height: 0;
          }
          #header-container.collapsed #location-string {
            width: 0;
            opacity: 0;
          }
        }

        .header {
          height: var(--header-height);
        }

        ${disableFixedPosition &&
        `
          body {
            padding-top: 0;
          }

          @media (min-width: 1025px) {
            body {
              padding-top: var(--header-height);
            }

            #header-container {
              position: fixed;
            }

            .full-header {
              height: 106px;
              overflow: hidden;
            }
          }`}
      `}</style>
    </>
  );
};
