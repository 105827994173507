import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";

import publicConfig from "config/public";
import { Action, Category } from "constants/events";
import { UserState } from "custom-types/User";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { useSsoUrls } from "hooks/useSsoUrls";
import { CLOSE_NAVIGATION } from "redux/reducers/header";
import {
  getNavigationOpen,
  getNotificationCount,
} from "redux/selectors/header";
import { getFullUser, getIsLoggedIn, getUserId } from "redux/selectors/user";
import { localizeText } from "utils/localizeText";

import { NotificationIndicator } from "components/Header/NotificationIndicator";
import { SidebarModal } from "components/Header/SidebarModal";
import Logo from "components/Icons/logo.svg";
import PhoneSimpleIcon from "components/Icons/phone_mobile.svg";
import CloseIcon from "components/Icons/x.svg";

import NavigationItem from "../NavigationItems/NavigationItem";
import NavigationItemAccordion from "../NavigationItems/NavigationItemAccordion";

const SidebarWrapper: React.FC<{
  children: ReactNode;
  returnFocusRef: React.RefObject<HTMLElement>;
}> = ({ children, returnFocusRef }) => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const user = useSelector(getFullUser);
  const dispatch = useDispatch();
  const countryCode = useDomainCountryCode();
  const open = useSelector(getNavigationOpen);
  const { publishEvent } = useEventTracker();
  const {
    imgix: { publicUrl: CDN_HOSTNAME },
  } = publicConfig;
  const trackLinkClick = (text: string) =>
    publishEvent({
      action: Action.click,
      category: Category.headerNavigation,
      label: text.toLowerCase(),
    });

  const onCloseClick = () => {
    trackLinkClick("hamburger - close");
    dispatch({ type: CLOSE_NAVIGATION });
  };

  return (
    <SidebarModal
      open={open}
      onCloseClick={onCloseClick}
      returnFocusRef={returnFocusRef}
    >
      <nav aria-label="Site" className="p-xl">
        <div className="flex items-center justify-between pb-5">
          <span className="text-lg">
            <Logo width="55" height="24" />
          </span>
          <button
            aria-label="Close navigation"
            className="nav__close text-default"
            onClick={onCloseClick}
            type="button"
          >
            <CloseIcon height="20" width="20" />
          </button>
        </div>
        <div className="flex flex-col gap-xl divide-y divide-light-grey">
          <SidebarAccountSection
            trackLinkClick={trackLinkClick}
            isLoggedIn={isLoggedIn}
            user={user}
            countryCode={countryCode}
          />

          {children}
          <SidebarAccountFooter
            trackLinkClick={trackLinkClick}
            cdnHostName={CDN_HOSTNAME}
          />
        </div>
      </nav>
    </SidebarModal>
  );
};

const SidebarAccountSection: React.FC<{
  trackLinkClick: (text: string, userId?: number) => void;
  isLoggedIn: boolean;
  user: UserState;
  countryCode: string;
}> = ({ trackLinkClick, isLoggedIn, user, countryCode }) => {
  const { signUpUrl, signInUrl } = useSsoUrls();
  const unreadCount = useSelector(getNotificationCount) || 0;

  return isLoggedIn ? (
    <ul className="flex flex-col gap-xl">
      <NavigationItem
        href="/me/edit-profile"
        className="fs-block"
        onClick={() => trackLinkClick("hamburger - name")}
      >
        Hi, {user.preferredName || user.email}
      </NavigationItem>

      <NavigationItem
        href="/order-history"
        onClick={() => trackLinkClick("hamburger - order history")}
      >
        {localizeText("Orders", countryCode)}
      </NavigationItem>

      <NavigationItem
        href={"/me/updates"}
        className="relative flex justify-between"
        onClick={() => trackLinkClick("hamburger - notifications indicator")}
      >
        Notifications
        {!!unreadCount && (
          <NotificationIndicator
            unreadCount={unreadCount}
            style={{ right: 0, top: "10%" }}
          />
        )}
      </NavigationItem>
    </ul>
  ) : (
    <ul className="flex flex-col gap-xl">
      <NavigationItem
        as="a"
        href={signInUrl}
        onClick={() => trackLinkClick("sign in")}
      >
        Sign in
      </NavigationItem>

      <NavigationItem
        as="a"
        href={signUpUrl}
        onClick={() => trackLinkClick("create account")}
      >
        Create account
      </NavigationItem>
    </ul>
  );
};

const SidebarAccountFooter: React.FC<{
  trackLinkClick: (text: string, userId?: number) => void;
  cdnHostName: string;
}> = ({ trackLinkClick, cdnHostName }) => {
  const countryCode = useDomainCountryCode();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const userId = useSelector(getUserId);

  const { signOutUrl } = useSsoUrls({
    signOut: { redirectUrlParams: { sign_out_redirect: "true" } },
  });

  const successUrl =
    countryCode === "CA"
      ? "https://success.leafly.ca"
      : "https://success.leafly.com";

  return (
    <>
      <ul className="flex flex-col gap-xl pt-xl">
        <NavigationItem
          aria-label="Download the Leafly app"
          as="a"
          href="https://onelink.to/leaflysidebar"
          onClick={() => trackLinkClick("Download the app")}
        >
          <span className="flex flex-row">
            <PhoneSimpleIcon className="mr-xs text-green" width="24" /> Download
            the Leafly App
          </span>
        </NavigationItem>
      </ul>
      <ul className="flex flex-col gap-xl pt-xl">
        <NavigationItem
          as="a"
          href={`${successUrl}?utm_source=hamburger_menu&utm_medium=navigation&utm_campaign=sell_on_leafly&utm_content=SOL`}
          onClick={() => trackLinkClick("Advertise on Leafly")}
        >
          Advertise on Leafly
        </NavigationItem>
        <NavigationItemAccordion
          section="Country"
          trackLinkClick={trackLinkClick}
        >
          <NavigationItem
            as="a"
            className="flex justify-between"
            href="https://www.leafly.com"
            onClick={() => trackLinkClick("Leafly.com")}
          >
            Leafly.com
            <img
              data-src={`${cdnHostName}/cephalopod/images/flag-usa.png`}
              alt="USA flag"
              className="lazyload rounded-full"
            />
          </NavigationItem>

          <NavigationItem
            as="a"
            className="flex justify-between"
            href="https://www.leafly.ca"
            onClick={() => trackLinkClick("Leafly.ca")}
          >
            Leafly.ca
            <img
              data-src={`${cdnHostName}/cephalopod/images/flag-canada.png`}
              alt="Canadian flag"
              className="lazyload rounded-full"
            />
          </NavigationItem>

          <NavigationItem
            as="a"
            className="flex justify-between"
            href="https://www.leafly.de"
            onClick={() => trackLinkClick("Leafly.de")}
          >
            Leafly.de
            <img
              data-src={`${cdnHostName}/cephalopod/images/flag-germany.png`}
              alt="German flag"
              className="lazyload rounded-full"
            />
          </NavigationItem>
        </NavigationItemAccordion>
        <NavigationItem
          aria-label="Help navigation"
          aria-labelledby="help-navigation"
          as="a"
          href="https://help.leafly.com"
          onClick={() => trackLinkClick("Help")}
        >
          Help
        </NavigationItem>
      </ul>
      {isLoggedIn && (
        <ul aria-label="Sign out" className="flex flex-col gap-xl pt-xl">
          <NavigationItem
            as="a"
            href={signOutUrl}
            onClick={() => trackLinkClick("sign out", userId)}
          >
            Sign out
          </NavigationItem>
        </ul>
      )}
    </>
  );
};

export default SidebarWrapper;
