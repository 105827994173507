import React from "react";

import { Header } from "components/Header";

/**
 * This component is the layout that is used for Pages Router pages, in
 * conjunction with the Layout component, which is used for pages using
 * both the Pages and App Router. All of the functionality here should
 * be migrated to the Layout component to bring parity between the two
 * routers, once it is possible to do so.
 */
const PagesRouterLayout: React.FC<{
  children?: React.ReactNode;
  disableFixedHeader?: boolean;
  disableHeaderCollapseOnScroll?: boolean;
  hideHeader?: boolean;
  isAppBannerCookieDismissed?: boolean;
  showMinimalHeader?: boolean;
}> = ({
  children,
  disableFixedHeader,
  disableHeaderCollapseOnScroll,
  hideHeader,
  isAppBannerCookieDismissed,
  showMinimalHeader,
}) => {
  return (
    <>
      {!hideHeader && (
        <Header
          disableFixedPosition={disableFixedHeader}
          disableScrollCollapse={disableHeaderCollapseOnScroll}
          isAppBannerCookieDismissed={isAppBannerCookieDismissed}
          minimal={showMinimalHeader}
        />
      )}

      <main>{children}</main>
    </>
  );
};

export default PagesRouterLayout;
