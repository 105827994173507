import React, {
  DetailedHTMLProps,
  memo,
  SourceHTMLAttributes,
  useMemo,
} from "react";

import generateImageSrcProps from "utils/image/generateImageSrcProps";
import getImageSourceUrl from "utils/image/getImageSourceUrl";
import imageScreenSizes from "utils/image/imageScreenSizes";

import { ImageProps } from "./Image";

type Props = Pick<
  ImageProps,
  "disableLazyLoad" | "mobileSrc" | "sizes" | "src" | "tabletSrc"
> & {
  params?: Record<string, string>;
};

export default memo(
  ({
    disableLazyLoad = false,
    mobileSrc,
    params,
    sizes,
    src,
    tabletSrc,
  }: Props) => {
    const srcProps = useMemo(() => {
      const props: DetailedHTMLProps<
        SourceHTMLAttributes<HTMLSourceElement>,
        HTMLSourceElement
      >[] = [];

      sizes.slice(0, 5).forEach((size: number | null, i: number) => {
        const mediaQuery = `(min-width: ${imageScreenSizes[i]}px)`;

        if (size) {
          const url = getImageSourceUrl(i, src, mobileSrc, tabletSrc);
          const imageSrcProps = generateImageSrcProps(
            url,
            size,
            disableLazyLoad,
            params,
            true,
          );

          props.push({
            ...imageSrcProps,
            key: `${url}-${size}-${i}`,
            media: mediaQuery,
          });
        }
      });

      return props.reverse();
    }, [disableLazyLoad, mobileSrc, `${params}`, `${sizes}`, src, tabletSrc]);

    return (
      <>
        {srcProps.map((srcProp) => (
          // eslint-disable-next-line react/jsx-key -- The key is on the srcProp object
          <source {...srcProp} />
        ))}
      </>
    );
  },
);
